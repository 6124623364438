.SummaryText {
  color: #596f92;
  font-size: 1.15rem;
  padding: 0.5rem;
}

.TableRow div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.TableHeader {
  background-color: #113063;
  color: white;
  text-align: center;
}

.TableHeader span {
  padding: 0.5rem;
  text-align: center;
  width: 20%;
}

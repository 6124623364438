.SortedColumn {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.SortingArrow {
  cursor: pointer;
  color: grey;
  opacity: 0.5;
  padding-left: 0.5rem;
}

.Active {
  color: inherit;
  opacity: 1;
}

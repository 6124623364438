.Wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  height: 100vh;
  max-width: 728px;
}

@media screen and (max-width: 728px) {
  .Wrapper {
    margin: 0 1rem;
  }
}

.Logo {
  max-width: 28rem;
}

.LoginLink {
  color: #409bfe;
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: left;
  text-decoration: none;
}

.LoginLink:visited {
  color: #409bfe;
}

.IconButton {
  border: none;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 10px;
}

.IconButton:hover,
.IconButton:focus {
  background-color: transparent;
}

.IconButton svg {
  margin: 0;
}

.Input {
  padding-right: 2rem;
}

.PasswordInputWrapper {
  display: flex;
  align-items: center;
  position: relative;
  max-width: 324px;
  width: 100%;
}

.Title {
  font-size: 37px;
  text-align: center;
}

.SmallBlurb {
  font-size: 0.75rem;
  max-width: 297px;
  width: 100%;
  margin: 1rem 0 40px;
  text-align: center;
}

.SetPasswordButton {
  max-width: 324px;
  width: 100%;
}

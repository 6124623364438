.ActionBox {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Input {
  width: 25rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.SpanMiddle {
  margin-left: 2rem;
  margin-right: 2rem;
}

.Controls {
  display: flex;
  flex-direction: column;
  left: 1rem;
  position: absolute;
  top: 2rem;
}

.Controls button {
  margin-bottom: 0.25rem;
}

.Controls button:last-child {
  margin-bottom: 0;
}

.InputHeader {
  display: flex;
  justify-content: space-between;
}

.Selector {
  color: #0f3162;
  width: 16rem;
}

.MarginLeft {
  margin-left: 1rem;
}

.FullWidth {
  width: 50rem;
}

.SmallSpinner {
  height: 1rem;
  width: 1rem;
}

.Toggle {
  margin-top: 1.4rem;
}

.ToggleWrapper {
  flex: 1;
}

.Wrapper {
  color: #0f3162;
}

.FeatureFlagWrapper {
  display: flex;
  margin-top: 4rem;
}

.Divider {
  border: 1px solid #ececed;
  width: 100%;
  margin: 0.75rem 0 2rem;
}

.Text {
  font-size: 1.25rem;
}

.BillingUserSelector {
  width: 200px;
  margin: 1rem 0 2.5rem;
}

.Dialog {
  padding: 2rem 1.5rem 1.5rem;
}

@media screen and (max-width: 900px) {
  .Dialog {
    width: 100%;
  }
}

.TitleContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.Title {
  align-self: flex-end;
  margin: 0;
}

.TitleButtons > * {
  margin-left: 0.5rem;
}

.BillingDetails {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
}

.BillingValue {
  margin: 2rem 0 2rem 0.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
}

.Center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/**
 * Section
 */

.Section {
  margin-bottom: 4rem;
}

.Section:first-child {
  margin: 0;
}

.SectionTitle {
  margin: 0;
}

.InviteInput {
  width: 15rem;
}

.Modal {
  background-color: white;
  left: 50%;
  padding: 3rem;
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50vh;
  z-index: 201;
  min-width: 40rem;
  width: 40%;
  text-align: left;
  overflow-y: auto;
  height: 40%;
}

.MarginRight {
  margin-right: 1rem;
}

.MarginLeft {
  margin-left: 1rem;
}

.MaxAcres {
  margin: 1rem auto;
}

.ErrorMessage {
  color: red;
}

.ButtonToolbar {
  text-align: right;
}

.Overlay {
  background-color: gray;
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 200;
}

.RemoveCancel {
  text-align: right;
}

.RemoveCancel button {
  margin-right: 1rem;
}

.SubTitle {
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

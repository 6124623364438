.AnalyticHeader {
  height: 3rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  border-radius: 2px;
}

.AnalyticName {
  width: 12rem;
  display: table-cell;
  vertical-align: middle;
}

.AnalyticHeaderText {
  width: 18rem;
  height: 100%;
}

.Center {
  display: flex;
  align-items: center;
}

.RiskText {
  width: 6rem;
  display: table-cell;
  vertical-align: middle;
}

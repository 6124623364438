.PanelWrapper {
  flex-grow: 1;
  padding: 0.2rem 1.5rem 0;
  overflow-y: scroll;
  height: 85vh;
  color: #113063;
}

.SelectorRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Selector {
  margin: 1rem 0;
  width: 15rem;
}

.SamplePlanSelector {
  margin: 1rem 0;
  width: 11rem;
}

.AnalyticResult {
  text-align: end;
  justify-content: flex-end;
}

.BottomBorder {
  border-bottom: 0.2rem solid #ececed;
}

.RiskText {
  margin-left: 5rem;
}

.Hover:hover {
  cursor: pointer;
}

@media print {
  .HideForPrint {
    display: none;
    visibility: hidden;
  }

  .PanelWrapper {
    display: block;
    padding: 0.2rem 1.5rem 0;
    overflow-y: visible;
    width: 100%;
    height: 100%;
    page-break-after: always;
  }
}

.GoBack {
  background: #ffffff;
  border: 1px solid #113063;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #113063;
  width: 10rem;
  height: 25px;
}

.TabbedController {
  margin-right: 1rem;
}

.NoResultsWrapper {
  text-align: center;
  margin-top: 4rem;
  color: #113063;
}

.MapbookWrapper {
  margin-top: 2rem;
}

.ChartWrapper {
  height: 12rem;
  width: 100%;
}

.HighModDisplay {
  position: absolute;
  margin-top: -5.5rem;
  color: #113063;
  font-weight: bold;
  font-size: 0.7rem;
  min-height: 5rem;
}

.HighModDisplay div {
  margin-bottom: 1rem;
}

.PaddingLeft {
  padding-left: 1rem;
}

.ChartRanges {
  margin-top: 1rem;
  color: #113063;
  font-size: 0.7rem;
  width: 100%;
  text-align: center;
  min-height: 5rem;
}

.ChartRanges div {
  margin-bottom: 1rem;
}

/* pixels consistent with recharts */
.ChartHeaderTick {
  width: 30px;
  line-break: auto;
  font-weight: bold;
  font-size: 0.8rem;
}

.ChartHeaderTick:hover {
  cursor: pointer;
  color: #647594;
}

.ChartBar:hover {
  cursor: pointer;
  color: #647594;
}

.HeaderForeign {
  width: 80px;
  text-align: center;
}

.StackNameAndRanges {
  width: inherit;
  min-height: 5rem;
  color: #113063;
}

.MapbookBar {
  background-color: #ededed;
  color: #113063;
  height: 2rem;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  margin-top: 2rem;
  font-weight: bold;
}

.CropImage {
  width: 3rem;
  height: 3rem;
}

.Popup {
  color: #0f3162;
  display: flex;
  flex-direction: column;
  min-width: 10rem;
}

@media screen and (max-width: 1300px) {
  .HeaderForeign {
    width: 60px;
    text-align: center;
  }
}

@media print {
  .HideForPrint {
    display: none;
    visibility: hidden;
  }

  .Wrapper {
    width: 100%;
  }
}

.Wrapper {
  width: 35rem;
  height: 20rem;
  margin: 1rem 3rem;
  color: #003166;
  text-align: center;
}

.Columns {
  display: flex;
  flex-flow: row;
}

.Columns > div {
  display: inline-block;
  width: 50%;
  vertical-align: top;
  margin: 0.5rem;
}

.Columns > div:first-child {
  border-right: 0.1rem solid #cfd5df;
}

.CheckboxWrapper {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 15rem;
  align-items: center;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.MarginTop {
  margin-top: 2rem;
}

.Selector {
  width: 10rem;
  margin-left: 1rem;
}

.Lengthen {
  width: 18rem;
  margin-left: 0;
  margin-top: 0;
}

.GenerateWrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 1rem;
}

.SpinnerWrapper {
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  margin: auto 0.2rem;
}

.SmallSpinner {
  height: 1rem;
  width: 1rem;
}

.BorderGroup {
  border: 0.1rem solid #cfd5df;
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 15rem;
}

.BorderField {
  width: 20rem;
  height: 25rem;
}

.FieldScroll {
  overflow-y: scroll;
  height: 20rem;
}

.Clickable {
  cursor: pointer;
}

.Clickable:hover,
.Clickable:focus {
  background-color: var(--mantine-color-gray-1);
}

.Clickable:active {
  background-color: var(--mantine-color-gray-3);
}

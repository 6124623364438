.FieldSelector {
  align-items: center;
  display: flex;
  flex-flow: row;
}

.Thumbnail {
  align-items: center;
  display: flex;
}

.FieldSelector .Thumbnail svg {
  height: 40px;
  margin-right: 0.5rem;
  width: 40px;
}

.Header {
  color: #878484;
}

.Title {
  margin-top: 2rem;
}

.Tooltip {
  border: 1px solid #d0d9e2;
  border-radius: 4px;
  box-shadow:
    inset 0 2px 0 0 rgba(255, 255, 255, 0.05),
    0 0 8px 4px rgba(218, 218, 218, 0.26);
  padding: 0.5rem;
}

.ActionButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.DialogButtons {
  margin-top: 2rem;
}

.DialogContent {
  text-align: center;
}

.DialogSelector {
  min-width: 20rem;
  text-align: left;
}

.DialogSelectorMenu > button {
  text-align: left;
}

.DialogFieldInput {
  max-width: 20rem;
  margin-top: 1rem;
}

.Tab {
  background: #ffffff;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid #003166;
  box-sizing: border-box;
  color: #003166;
  cursor: pointer;
  font-size: 1rem;
  font-style: normal;
  font-weight: bold;
  line-height: 1.15;
  margin-right: 0.6875rem;
  padding: 0.25rem 2.25rem;
}

.Tab:last-child {
  margin-right: 0;
}

.Tab.Active {
  background-color: #003166;
  color: #ffffff;
  line-height: 1.15;
  text-align: center;
}

.Tabs {
  border-bottom: 1px solid #b4b4b4;
  display: flex;
  flex-direction: row;
  padding: 0 1.375rem;
}

.Table {
  height: calc(100vh - 11.5rem);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.Table .TableHeader {
  position: sticky;
  top: 0;
  background-color: white;
}

.Toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Toolbar > * {
  margin-right: 1rem;
}

.Toolbar > *:last-child {
  margin-right: 0;
}

.Search {
  margin-left: auto;
}

.RecSetName {
  justify-content: flex-start;
}

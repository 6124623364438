.Wrapper {
  color: #0f3161;
}

.Error {
  color: red;
  margin: 1rem 0;
}

.PasswordWrapper {
  width: 100%;
}

.Row {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
}

.Selector {
  width: 16rem;
}

.LabelAndRadio {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 2rem;
}

.Submit {
  margin-top: 2rem;
}

.Submit span {
  margin-left: 1rem;
  color: red;
}

.FormControlContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
}

.FormBillingAddressInput {
  grid-column-start: 1;
  grid-column-end: 3;
}

.RegisterAgentAbout {
  color: #031166;
  width: 50rem;
  margin-bottom: 2rem;
}

.Tooltip {
  background-color: #fff;
  border: 1px solid #d0d9e2;
  border-radius: 4px;
  box-shadow:
    inset 0 2px 0 0 rgba(255, 255, 255, 0.05),
    0 0 8px 4px rgba(218, 218, 218, 0.26);
  color: #4a4a4a;
  font-size: 0.75rem;
  font-weight: 500;
  min-width: 10rem;
  width: 100%;
  padding: 0.4rem;
}

.LabelWithTooltip {
  position: relative;
  display: flex;
  align-items: center;
}

.TooltipLabel {
  font-size: 20px;
  line-height: 1.2;
  color: #031166;
}

.TooltipLabel span {
  margin: 0;
}

.HelpIcon {
  margin-left: 0.5rem;
}

.HelpIcon svg {
  width: 1rem;
  height: 1rem;
}

.RadioControl {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  width: 50rem;
}

.RadioItem {
  display: flex;
  align-items: center;
  margin-right: 2rem;
  font-size: 20px;
  line-height: 1.2;
  color: #031166;
}

.RadioItem input {
  margin-right: 12px;
}

.RegisterTypeAhead {
  margin-bottom: 1rem;
}

.Tag {
  align-items: center;
  display: inline-block;
  height: 1.5rem;
  margin-top: 0.5rem;
  min-width: 14rem;
  white-space: nowrap;
  color: #031166;
}

.Tag span:last-child {
  cursor: pointer;
  margin-left: 0.2rem;
}

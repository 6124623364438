.Toggle {
  --circle-color: white;
  --disabled-color: #ddd;
  --enabled-color: #003166;

  --circle-height: 1.625rem;
  --circle-width: 1.625rem;
  --height: 2.25rem;

  display: inline-block;
  height: var(--height);
  position: relative;
  width: calc(var(--height) * 1.75);
}

.Slider {
  background-color: var(--disabled-color);
  border-radius: var(--height);
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.Slider:before {
  background-color: var(--circle-color);
  border-radius: 50%;
  bottom: calc((var(--height) - var(--circle-height)) / 2);
  content: '';
  height: var(--circle-height);
  left: calc((var(--height) - var(--circle-height)) / 2);
  position: absolute;
  transition: 0.4s;
  width: var(--circle-width);
}

.Toggle input {
  height: 0;
  opacity: 0;
  outline: none;
  width: 0;
}

.Toggle input:focus {
  outline: 0;
}

.Toggle input:checked + .Slider {
  background-color: var(--enabled-color);
}

.Toggle input:focus + .Slider {
  outline: none;
  box-shadow:
    0 0 0 0.1875rem rgba(221, 221, 221, 0.5),
    0 0 0 0.09375rem rgba(221, 221, 221, 0.5);
}

.Toggle input:checked + .Slider:before {
  transform: translateX(var(--circle-width));
}

.Wrapper {
  display: flex;
  flex-direction: column;
}

.FlexContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.SaveButtons {
  margin-left: auto;
}

.SpaceBetween {
  justify-content: space-between;
}

.MainAttributes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.MainAttributes > * {
  margin-right: 1rem;
}

.MainAttributes > *:last-child {
  margin-right: 0;
}

.Table .TableRow {
  border: none;
}

.Table .TableCol {
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.Table .TableCol:first-child > div {
  margin-left: 0;
}

.CategorySelector {
  width: 15rem;
}

.AnalyticImage {
  width: 3.2rem;
  height: 3.2rem;
  overflow: hidden;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  width: 20rem;
  padding: 0 1rem 1rem;
  margin-top: -2rem;
  text-align: center;
}

.Title {
  font-weight: 600;
  font-size: 1.7rem;
}

.Message {
  margin-top: 1.2rem;
}

.FileInput {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  align-items: center;
  margin-top: 1.2rem;
}

.FileName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.FileButton {
  display: flex;
  flex-direction: row;
  padding-right: 2rem;
}

.FileButton svg {
  margin-right: 0.5rem;
}

.Error {
  color: red;
}

.Table {
  box-sizing: border-box;
  font-weight: normal;
  line-height: 1.33;
}

.TableRow {
  align-items: center;
  border-bottom: 1px solid #ececed;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

.TableRow:last-child {
  border-bottom: none;
}

.TableRow.Borderless {
  border-bottom: none;
}

.TableRow.Hoverable {
  position: relative;
}

.TableCol {
  box-sizing: border-box;
  flex: 1 1 0;
  width: 0;
  word-wrap: break-word;
}

.Header .TableCol {
  color: #878484;
  font-size: 1em;
  line-height: 1.33;
}

.Header .TableColWrapper {
  margin: 1rem 1rem 0.625rem 0;
}

.x2 {
  flex-grow: 2;
}

.x3 {
  flex-grow: 3;
}

.x4 {
  flex-grow: 4;
}

.Clickable {
  cursor: pointer;
}

.TableColWrapper {
  margin: 1rem 1rem 1rem 0;
}

.TableCol:last-child .TableColWrapper {
  margin-right: 0;
}

/* if there is an extra element for tooltip, we need to reset the 2nd last child */
.Hoverable .TableCol:nth-last-child(2) .TableColWrapper {
  margin-right: 0;
}

.Shrink {
  flex: 0 1 auto;
}

.TooltipWrapper {
  position: relative;
  width: 0;
  height: 0;
  z-index: 10;
}

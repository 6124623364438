.Wrapper {
  max-width: 890px;
  width: 100%;
  text-align: center;
  margin: 3rem auto;
}

.Buttons {
  display: flex;
  justify-content: center;
  margin: 3rem 0;
}

.Button {
  max-width: 320px;
  width: 100%;
  margin: 0 1rem;
}

.Title {
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .Buttons {
    display: block;
  }

  .Button {
    max-width: unset;
    margin: 1rem 0;
  }
}

.ChartWrapper {
  width: 100%;
  min-height: 30rem;
}

.ChartPaper {
  width: 100%;
  min-height: 30rem;
  padding: 1rem;
  background-color: #f2f7ff;
}

.HeaderForeign {
  width: 80px;
  text-align: center;
}

.StackNameAndRanges {
  width: inherit;
  min-height: 5rem;
  width: 4rem;
  text-align: center;
  justify-content: center;
}

.ChartHeaderTick {
  width: 30px;
  line-break: auto;
  font-weight: bold;
  font-size: 0.8rem;
}

.LevelPaper {
  align-items: center;
  justify-content: center;
  margin-left: 5%;
  display: flex;
  width: 90%;
}

.VerticalCenter {
  justify-content: center;
  display: flex;
  align-items: center;
}

.LeftAlign {
  text-align: left;
  align-items: center;
  display: flex;
}

.BorderBottom {
  border-bottom: 1px solid #d3d3d3;
}

.AddInput {
  width: 100%;
  padding: 1rem 3rem;
  background-color: #f2f7ff;
}

.Title {
  margin: 6.25rem 0 2.375rem;
  font-size: 2.25rem;
  font-weight: 700;
}

.Container {
  max-width: 741px;
  width: 100%;
  font-size: 24px;
  line-height: 1.25;
  text-align: center;
  color: #113063;
}

.Footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
  margin-bottom: 2.5rem;
}

.AcceptButton {
  margin-right: 35px;
  padding: 8px 40px;
}

.LogoutButton {
  padding: 8px 27px;
}

.Error {
  color: red;
  font-size: 12px;
  margin-top: 4px;
  text-align: center;
}

.TermsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TermsContainer span:hover {
  cursor: pointer;
}

.AgreementLinksWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.Link {
  color: #0347ff;
  cursor: pointer;
  text-decoration: none;
}

.AgreementText {
  max-width: 40rem;
}

.CheckboxTextWrapper {
  display: flex;
  margin-right: 0.5rem;
}

.CheckboxText {
  color: red;
  margin: 0 0 0 0.5rem;
}

@media screen and (max-width: 768px) {
  .Title {
    margin: 3.375rem 0;
    font-size: 1.75rem;
  }

  .Footer {
    display: block;
  }

  .AcceptButton {
    margin-right: 0;
    margin-bottom: 1rem;
    width: 100%;
  }

  .LogoutButton {
    width: 100%;
  }
}

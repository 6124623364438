.ErrorText {
  color: red;
}

.ForgotLink {
  color: #409bfe;
  font-size: 0.75rem;
  margin-bottom: 1rem;
  text-align: left;
  text-decoration: none;
}

.ForgotLink:visited {
  color: #409bfe;
}

.Input {
  margin: 0.5rem 0;
}

.InputEmail {
  width: 15rem;
}

.LoginButton {
  margin-top: 1rem;
}

.Spinner {
  height: 0.5rem;
  margin: 0 auto;
  width: 0.5rem;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  min-width: 16rem;
}

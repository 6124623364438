.Header {
  background-color: #0f3161;
  color: white;
  height: 2rem;
  padding: 0.3rem;
}

.HeaderRowValue {
  width: 8rem;
  display: table-cell;
  vertical-align: middle;
  height: 2rem;
}

.Quantity {
  padding: 0.2rem;
  width: 6rem;
}

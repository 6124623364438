.Menu {
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #d0d9e2;
  box-shadow:
    inset 0 2px 0 0 rgba(255, 255, 255, 0.05),
    0 0 8px 4px rgba(218, 218, 218, 0.26);
  color: #4a4a4a;
  display: flex;
  flex-direction: column;
  font-size: inherit;
  max-height: 15em;
  overflow: auto;
  padding: 0.25rem 0;
  position: absolute;
  width: 100%;
  z-index: 999;
}

.Wrapper {
  display: inline-block;
  position: relative;
  font: inherit;
}

.Wrapper * {
  box-sizing: border-box;
}

.ClearButton {
  --color: #808080;
  --background-color: white;
  --hover-color: #003166;
  --hover-background-color: white;

  border: 0;
  border-radius: 1rem;
  padding: 0.1rem 0.1rem;
  position: absolute;
  right: 0.2rem;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 10;
}

.RightSpace {
  right: 2rem;
}

.ClearButton svg {
  display: inline-block;
  margin: 0;
  vertical-align: middle;
}

.Input {
  width: 100%;
}

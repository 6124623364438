.FieldName {
  min-width: 3rem;
  text-align: left;
  word-wrap: break-word;
}

.FieldName:hover {
  cursor: pointer;
  opacity: 0.5;
}

.FailedP {
  color: red;
}

.CropSelect {
  width: 100%;
  height: 3rem;
  cursor: pointer;
}

.CropInput {
  width: 100%;
  height: 3rem;
}

.CropImgBox:hover {
  opacity: 0.5;
}

.Hover:hover {
  cursor: pointer;
}

.RowValue {
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.Border {
  border: 2px solid #013066;
}

@media print {
  .FieldRow {
    break-inside: avoid !important;
  }
}

.Pagination {
  text-align: center;
}

.SpinnerWrapper {
  display: block;
  text-align: center;
}

.Spinner {
  width: 1rem;
  height: 1rem;
  /*margin: 20vh auto;*/
}

.SearchBar {
  width: 15rem;
}

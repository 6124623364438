.Spinner {
  width: 1rem;
  height: 1rem;
}

.NoFiles {
  height: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
}

.Prescription {
  border-bottom: 1px solid #ececed;
}

.Prescription:last-child {
  border-bottom: none;
}

.Red {
  color: #e68080;
}

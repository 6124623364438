.Wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.ImageContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 1.5rem;
  margin-right: 1rem;
}

.Image {
  width: 100%;
  margin-bottom: 0.5rem;
  text-align: center;
  overflow: hidden;
}

.ImgTitle {
  text-align: center;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #003166;
  margin-top: 0.1rem;
}

.Source {
  text-align: center;
  font-size: 0.7rem;
  color: #003166;
  width: 100%;
  word-wrap: break-word;
  margin-top: 0.1rem;
}

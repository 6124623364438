.Wrapper {
  color: #003166;
  display: flex;
  flex-direction: column;
  margin-top: 10%;
  text-align: center;
}

.Title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 3rem;
}

.Button {
  font-size: 24px;
  font-weight: 400;
  margin: auto;
  padding: 18px 18px 28px;
}

.Logo {
  min-height: 100%;
  max-width: 25rem;
  margin: 7% auto 2rem auto;
}

.DemoAccountTitle {
  margin: 3rem;
}

.DemoButton {
  margin: auto;
}

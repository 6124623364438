.MapWrapper {
  flex: 1;
  position: relative;
  width: 100%;
  height: 100%;
}

.Navigation {
  bottom: 2rem;
  position: absolute;
  right: 1rem;
}

.Recenter {
  position: absolute;
  bottom: 2rem;
  right: 4rem;
}

.Toggle {
  position: absolute;
  top: 10px;
  right: 10px;
}

.Toolbar {
  position: absolute;
  top: 1rem;
  left: 1rem;
  min-width: 12rem;
}

.Wrapper {
  flex: 1;
  position: relative;
}

@media print {
  .Wrapper {
    width: 60rem;
    height: 50rem;
    display: block;
  }

  .Recenter {
    display: none;
    visibility: hidden;
  }
}

.PrintOnly {
  visibility: hidden;
  display: none;
}

@media print {
  @page {
    size: 8.5in 10in;
    padding: 0.2in 0.2in 0.5in 0.2in;
  }

  .Body {
    height: 100vh;
  }

  .PrintOnly {
    visibility: visible;
    display: flex;
  }

  .Logo {
    height: 2rem;
  }
}

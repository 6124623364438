.RadioOption {
  position: relative;
  cursor: pointer;
  padding: 0 0.7rem 0 0.7rem;
  margin: 1rem 0 1rem 0;
}

.RadioOption input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.RadioLabel {
  padding-left: 1.7rem;
  display: inline-block;
}

.RadioButton {
  position: absolute;
  top: -2px;
  left: 0;
  height: 1.25rem;
  width: 1.25rem;
  background-color: white;
  border-radius: 50%;
  border: 1px solid #003166;
}

.RadioOption:hover input ~ .RadioButton {
  background-color: lightgrey;
}

.RadioButton:after {
  content: '';
  display: none;
}

.RadioOption input:checked ~ .RadioButton:after {
  display: block;
}

.RadioOption .RadioButton:after {
  transform: translate(20%, 20%);
  width: 70%;
  height: 70%;
  border-radius: 50%;
  background: #003166;
}

.Dialog {
  padding: 1rem;
}

.Center {
  text-align: center;
}

.PackageInfo {
  margin: auto auto;
  width: 90%;
}

.PackageInfo .FormRow:first-child,
.FormRow div:last-child,
.Total,
.FieldInput {
  font-weight: bold;
}

.FormRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.HightlightDiscount {
  color: red;
}

.Form h1 {
  color: #003166;
}

.FormRow div {
  min-width: 15%;
  text-align: center;
}

.FormRow div:first-child {
  min-width: 40%;
  text-align: left;
}

.Total {
  width: 100%;
  border-top: 0.2rem solid #ececed;
  padding-top: 0.5rem;
}

.Width90 {
  width: 90%;
}

.FormControlContainer {
  margin: 2rem 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
}

.AgencyDetails {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
}

.AgencyValue {
  margin: 2rem 0 2rem 0.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
}

.SmallSpinner {
  height: 1rem;
  margin: 0 auto;
  width: 1rem;
}

.MetaWrapper {
  display: flex;
  align-items: center;
}

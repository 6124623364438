.MapWrapper {
  position: relative;
  flex: 1;
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

.Recenter {
  position: absolute;
  bottom: 2rem;
  right: 4rem;
}

.Wrapper {
  width: 100%;
  height: 40rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.HoverOver:hover {
  cursor: pointer;
}

.MapButtons {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.MapButtons button {
  margin-bottom: 0.5rem;
}

.MapButtonsBottomRow {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  position: absolute;
  bottom: 2rem;
  left: 2rem;
}

.MapLegendTopLeft {
  position: absolute;
  top: 2rem;
  left: 2rem;
  background-color: white;
  width: 12rem;
}

.Button {
  --color: #003166;
  --background-color: #fff;
  --hover-color: #003166;
  --hover-background-color: #d9d9d9;

  /* reset */
  padding: 0;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  outline: none;

  /* normalize */
  display: inline-block;
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;

  background-color: var(--background-color);
  border-radius: 0.25rem;
  border: 1px solid #003166;
  color: var(--color);
  font-weight: bold;
  line-height: 1.15;
  margin: 0;
  padding: 0.5em 1em;
}

.Button:focus,
.Button:hover {
  background-color: var(--hover-background-color);
  color: var(--hover-color);
}

.Button:focus {
  outline: none;
}

.Button:active {
  filter: saturate(150%);
}

.Button:disabled,
.Button[disabled] {
  --color: #99a5b1;
  --background-color: #fff;
  --hover-color: #99a5b1;
  --hover-background-color: #fff;

  cursor: not-allowed;
}

.Button > * {
  vertical-align: middle;
}

.Primary {
  --color: #fff;
  --background-color: #003166;
  --hover-color: #fff;
  --hover-background-color: #00244b;

  border: 1px solid #0f3162;
}

.Primary:disabled,
.Primary[disabled] {
  --color: #99a5b1;
  --background-color: #39536f;
  --hover-color: #99a5b1;
  --hover-background-color: #39536f;

  border: 1px solid #39536f;
}

.Danger {
  --color: #fff;
  --background-color: #d72e3d;

  --hover-color: #fff;
  --hover-background-color: #9d222d;

  border: 1px solid #d72e3d;
}

.Danger:disabled,
.Danger[disabled] {
  --color: #fff;
  --background-color: #d72e3d;

  --hover-color: #fff;
  --hover-background-color: #d72e3d;
}

/* Styles for inline SVG icons */
.Button svg {
  display: inline-block;
  height: 1.15em;
  vertical-align: middle;
  width: 1.15em;
  margin-left: 0.5rem;
  margin-right: -0.25rem;
}

.Button svg :global(.background) {
  fill: var(--background-color);
}

.Button svg :global(.foreground) {
  fill: var(--color);
}

.Button:focus svg :global(.background),
.Button:hover svg :global(.background) {
  fill: var(--hover-background-color);
}

.Button:focus svg :global(.foreground),
.Button:hover svg :global(.foreground) {
  fill: var(--hover-color);
}

.Button svg :global(.stroke-background) {
  stroke: var(--background-color);
}

.Button svg :global(.stroke-foreground) {
  stroke: var(--color);
}

.Button:focus svg :global(.stroke-background),
.Button:hover svg :global(.stroke-background) {
  stroke: var(--hover-background-color);
}

.Button:focus svg :global(.stroke-foreground),
.Button:hover svg :global(.stroke-foreground) {
  stroke: var(--hover-color);
}
/* End of styles for inline SVG icons */

.Circle {
  border-radius: 50%;
  padding: 0;
  min-width: 3rem;
}

.Circle:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  padding-top: 100%;
}

.Circle svg {
  display: inline-block;
  margin: 0;
  vertical-align: middle;
}

.Square {
  border-radius: 0;
}

.Light {
  --color: #003166;
  --background-color: #fff;
  --hover-color: #fff;
  --hover-background-color: #003166;

  border: 1px solid transparent;
}

.Inactive {
  --color: #5e6871;
  --background-color: #fff;
  --hover-color: #fff;
  --hover-background-color: #003166;

  border: 1px solid transparent;
}

.EditButton {
  margin: auto;
}

.EditConfirmationButtons {
  align-items: center;
  display: flex;
  margin-top: 2rem;
}

.EditConfirmationButtons button:first-child {
  margin-right: 1rem;
}

.Icon {
  width: 1.5rem;
  height: 1.5rem;
}

.Legend {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 4px;
  bottom: 0rem;
  box-shadow:
    inset 0 2px 0 0 rgba(255, 255, 255, 0.05),
    0 0 8px 4px rgba(218, 218, 218, 0.16);
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 1rem;
  position: absolute;
  right: 0rem;
  width: fit-content;
}

.Legend span {
  align-items: center;
  color: #113063;
  display: flex;
  margin-top: 0.5rem;
  white-space: nowrap;
}

.LegendDot {
  border: 1px solid #aaaeb2;
  border-radius: 50%;
  height: 1rem;
  margin-right: 0.5rem;
  width: 1rem;
}

.NoData {
  background-color: #aaaeb2;
  border-radius: 50%;
  height: 1rem;
  margin-right: 0.5rem;
  width: 1rem;
}

.Unit {
  margin: auto;
}

.Wrapper {
  bottom: 7rem;
  position: absolute;
  right: 1rem;
}

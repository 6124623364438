.TooltipContent {
  background-color: #fff;
  border: 1px solid #d0d9e2;
  border-radius: 4px;
  box-shadow:
    inset 0 2px 0 0 rgba(255, 255, 255, 0.05),
    0 0 8px 4px rgba(218, 218, 218, 0.26);
  color: #4a4a4a;
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  min-width: 10rem;
  padding: 0.25rem 0;
}

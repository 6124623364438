.BigColumn {
  width: 40%;
}

.InputArea {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.InputRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.InputRow:first-child {
  margin-bottom: 1rem;
}

.SmallColumn {
  width: 15%;
}

.Submit {
  height: 2.2rem;
}

.Wrapper {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  text-align: left;
}

.WellDialog > div {
  width: 35rem;
  max-height: calc(100% - 20rem);
  overflow-y: auto;
}

.InternalContainer {
  display: flex;
  justify-content: center;
}

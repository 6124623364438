.VerticalAlign {
  position: relative;
  height: auto;
}

.SpanInDiv {
  display: block;
  white-space: nowrap;
  max-width: 10rem;
}

.SpanInDivWrap {
  display: block;
  max-width: 10rem;
}

.SmallFont {
  font-size: 0.9rem;
}

.TopBottomBorder {
  border-top: 1px dashed black;
  border-bottom: 1px dashed black;
}

.AlertCol {
  padding: 0.1rem;
  color: white;
  font-size: 0.8rem;
  margin-right: 0.5rem;
}

.AlertColRed {
  background-color: #e88484;
}

.ColAlignRight {
  text-align: end;
}

.IconRight {
  padding-right: 2rem;
}

.SortableColStr:hover {
  font-weight: bold;
  color: #113063;
  cursor: pointer;
}

.NoWrap {
  white-space: nowrap;
  word-wrap: none;
}

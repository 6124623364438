.TabbedController {
  height: 1rem;
  width: 20rem;
  font-size: 1rem;
}

.Tag {
  align-items: center;
  display: inline-block;
  height: 1.5rem;
  margin-top: 0.5rem;
  min-width: 14rem;
  white-space: nowrap;
}

.Tag span:last-child {
  cursor: pointer;
  margin-left: 0.2rem;
}

.Input {
  width: 14rem;
}

.Pagination {
  text-align: center;
}

.SearchBar {
  width: 18rem;
  transition: 0.25s;
}

.SearchBar:hover,
.SearchBar:focus-within {
  width: 20rem;
}

.MiniSpinner {
  width: 1rem;
  height: 1rem;
}

.Spinner {
  width: 1rem;
  height: 1rem;
}

.NoFiles {
  height: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
}

.Link {
  color: #2f80ed;
  text-decoration: none;
}

.Link:hover {
  text-decoration: underline;
}

.Count {
  color: black;
  font-weight: 500;
}

.InfoText {
  color: #878484;
  text-align: left;
  font-size: 0.8rem;
  max-width: 18rem;
  padding-left: 3rem;
}

.Header {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  align-items: center;
}

.Pagination {
  text-align: center;
}

.Checkbox {
  position: relative;
  top: -0.2rem;
}

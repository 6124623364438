.Container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 3rem;
}

.Upload {
  margin: 2rem 0;
}

.Upload svg {
  margin-right: 1rem;
}

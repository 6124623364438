.Checkbox {
  height: 1.6rem;
  width: 1.6rem;
  margin: 0.1rem;
}

.CheckboxContent {
  position: relative;
  cursor: pointer;
  padding: 0.2rem 0.8rem;
}

.CheckboxContent.Unclickable {
  cursor: not-allowed;
}

.Checkbox input {
  position: absolute;
  opacity: 0;
}

.CheckboxLabel {
  padding-left: 1.25rem;
  display: inline-block;
}

.CheckboxButton {
  position: absolute;
  top: 0.2rem;
  left: 0;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 20%;
}

.CheckboxButton:after {
  content: '';
  display: none;
}

.Checkbox input:checked ~ .CheckboxButton:after {
  display: block;
}

.Checkbox input ~ .CheckboxButton:after {
  position: absolute;
  left: 0.45rem;
  bottom: 0.35rem;
  width: 0.5rem;
  height: 1rem;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.Checkbox:hover input ~ .CheckboxButton.Light {
  background-color: lightgrey;
}

.CheckboxButton.Light {
  background-color: white;
  border: 1px solid #003166;
}

.Checkbox .CheckboxButton.Light:after {
  border: solid #003166;
  border-width: 0 2px 2px 0;
}

.Checkbox:hover input ~ .CheckboxButton.Dark {
  background-color: #003166;
}

.Checkbox input:checked ~ .CheckboxButton.Dark {
  background-color: #003166;
}

.CheckboxButton.Dark {
  border: 1px solid #003166;
}

.Checkbox .CheckboxButton.Dark:after {
  border: solid white;
  border-width: 0 2px 2px 0;
}

.Checkbox .Disabled input ~ .CheckboxButton:after {
  display: block;
  position: absolute;
  top: 0.65rem;
  left: 0.2rem;
  width: 1rem;
  height: 0.02rem;
  background-color: white;
  transform: rotate(0);
}

.Checkbox .Disabled input ~ .CheckboxButton.Dark {
  background-color: #003166;
}

.Checkbox .Disabled input ~ .CheckboxButton.Light {
  background-color: white;
}

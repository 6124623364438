.Body {
  display: flex;
  height: 100%;
  justify-content: space-between;
}

.EmailInput {
  width: 18rem;
}

.Error {
  color: red;
  margin: 1rem;
}

.Logo {
  min-height: 100%;
  max-width: 25rem;
  margin: 7% auto 2rem auto;
}

.SmallBlurb {
  font-size: 0.75rem;
  margin: 1rem 0;
  max-width: 12rem;
  text-align: center;
}

.Wrapper {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.LoginSelectForm {
  width: 20rem;
}

.WrapText {
  width: 17rem;
}

.Error {
  color: red;
}

.SubmitButton {
  width: 10rem;
}

.InputWidth {
  width: 25rem;
}

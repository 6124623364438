.Content {
  page-break-after: always;
}

.MetadataSection {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: var(--mantine-spacing-xs);
}

.MetadataSection b {
  text-align: right;
}

@media print {
  td,
  th {
    border: 1px solid var(--table-border-color, var(--mantine-color-gray-3));
  }
}

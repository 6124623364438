.Icon {
  width: 0.75rem;
  height: 0.75rem;
}

.Icon :global(.foreground) {
  fill: #003166;
}

.Icon :global(.background) {
  fill: white;
}

.Icon :global(.stroke-foreground) {
  stroke: #003166;
}

.Icon :global(.stroke-background) {
  stroke: white;
}

.CheckboxWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.5rem;
}

.Right {
  flex-direction: row-reverse;
}

.Close {
  margin-top: 1rem;
}

.FileName {
  color: #315782;
  margin-top: 1rem;
}

.Modal {
  align-items: center;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  left: 50%;
  padding: 3rem;
  position: absolute;
  text-align: center;
  top: 30%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.Modal h1 {
  font-weight: bold;
}

.Overlay {
  background-color: gray;
  height: 100%;
  left: 0;
  opacity: 0.3;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;
}

.StatusBar {
  background-color: #003166;
  height: 1.5rem;
}

.StatusBarWrapper {
  background-color: #d2d2d2;
  margin: 1rem;
  width: 70%;
}

.Warning {
  margin: 1rem 0;
}

.Row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 2rem;
}

.Column {
  width: 48%;
  min-width: 30rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 1rem;
  margin-bottom: 1rem;
}

.GoBack {
  background: #ffffff;
  border: 1px solid #113063;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #113063;
  width: 11rem;
  height: 1.6rem;
}

.DoNotDisplay {
  display: none;
}

.Header {
  display: flex;
}

.FieldMap {
  margin: 1rem 0;
  display: flex;
  min-height: 18rem;
  justify-content: center;

  @media screen and (max-width: 36em) {
    flex-direction: column;
  }
}

.FieldMapInfo {
  @media screen and (min-width: 36em) {
    margin-right: 1rem;
    width: 60%;
  }
}

.FieldMapInfoFullWidth {
  @media screen and (min-width: 36em) {
    width: 100%;
  }
}

.LongerContent {
  height: 24rem;
}

.MapWrapper {
  @media screen and (min-width: 36em) {
    width: 40%;
  }
}

.MapWrapperFullWidth {
  @media screen and (min-width: 36em) {
    width: 100%;
  }
}

.Map {
  flex: 0 0 auto;
  height: 18rem;
  width: 100%;
}

.AnalyticHeader {
  padding: 0.5rem;

  @media screen and (min-width: 36em) {
    padding: 0 1rem;
    height: 3rem;
  }
}

.Recommendation {
  color: red;
  cursor: pointer;
}

.DisplayPrintOnly {
  display: none;
}

.Overlay {
  background-color: gray;
  height: 100%;
  left: 0;
  opacity: 0.7;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 102;
}

.Disclaimer {
  font-size: 0.8rem;
}

@media print {
  .FieldMap {
    break-inside: avoid !important;
    width: 60rem;
  }

  .FieldMapInfo {
    font-size: 0.7rem;
    width: 45%;
    min-width: 23rem;
  }

  .FieldMapInfoFullWidth {
    font-size: 0.7rem;
    width: 100%;
    min-width: 23rem;
  }

  .MapWrapper {
    width: 55%;
    min-width: 22rem;
  }

  .MapWrapperFullWidth {
    width: 100%;
    min-width: 22rem;
  }

  .AnalyticHeader {
    font-size: 0.75rem;
    line-height: 1rem;
    height: 2.5rem;
  }

  .FieldSet {
    display: block;
    page-break-after: always;
    page-break-inside: avoid;
    page-break-before: avoid;
  }

  .HideForPrint {
    display: none;
    visibility: hidden;
  }

  .DisplayPrintOnly {
    display: flex;
  }
}

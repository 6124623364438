.Wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 30rem;
  margin: 5rem auto;
}

.Wrapper div {
  margin-top: 5rem;
}

.Logo {
  height: 6rem;
  margin: 12% auto 1% auto;
}

.OptOutText {
  font-weight: 500;
  color: #0f3161;
  margin: 0 0.2rem;
}

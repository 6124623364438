.SortedColumn {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.SortingArrow {
  cursor: pointer;
  color: grey;
  opacity: 0.5;
}

.Active {
  color: inherit;
  opacity: 1;
}

.Body {
  padding: 1.5rem 0;
}

.EmailNote {
  font-size: 0.875rem;
  font-style: italic;
  margin-top: 1.75rem;
}

.FormControl {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
}

.Spinner {
  border: 3px solid rgba(0, 49, 102, 0.3);
  border-top-color: #ffffff;
  height: 1rem;
  margin: 0 auto;
  width: 1rem;
}

.Wrapper {
  margin-top: 2.5rem;
}

.FormControlContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
}

.FormBillingAddressInput {
  grid-column-start: 1;
  grid-column-end: 3;
}

.WrapperRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 1rem;
  width: 100%;
}

.StreetInput {
  min-width: 50rem;
}

.ThreeWideInput {
  margin-right: 1rem;
  min-width: 16rem;
}

.WiderInput {
  min-width: 20rem;
  margin-right: 1rem;
}

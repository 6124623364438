.Row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 2rem;
}

.Column {
  width: 48%;
  min-width: 30rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 1rem;
  margin-bottom: 1rem;
}

.Header {
  display: flex;
}
.Name {
  align-items: center;
  border-radius: 4px;
  border: 2px solid;
  display: flex;
  padding: 0 0.6rem;
}

.Info {
  display: flex;
  flex-flow: column;
  padding-left: 1rem;
}

.Type {
  padding: 0.3px 0px;
}

.Info span {
  font-size: 1rem;
}

.Action {
  text-align: right;
}

.Wrapper .Table div {
  color: #003166;
}

.TableHeader {
  font-weight: bold;
}

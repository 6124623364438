.StackWrapper {
  width: 100%;
  margin-bottom: 15rem;
}

.CropIconWrapper {
  border-bottom: 1px solid #c3cbd8;
  margin: auto 0;
  height: 9rem;
  display: flex;
}

.CropIcon {
  align-self: flex-end;
  margin-bottom: 1rem;
}

.HeaderColWrapper {
  position: relative;
  display: flex;
  text-align: flex-start;
  justify-content: center;
  cursor: pointer;
  height: 9rem;
  /* margin: auto 0; */
  border-bottom: 1px solid #c3cbd8;
}

.HeaderColWrapper span {
  display: inline-block;
  align-self: flex-end;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  margin-bottom: 1rem;
}

.StickyHeader {
  position: sticky;
  background-color: #ffffff;
  top: 3rem;
  z-index: 2;
  height: 9rem;
}

.WrapCropFields {
  break-after: auto;
}

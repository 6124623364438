.legendWrap {
  --map-nav-ctrls-gutter: 10px;
  --map-nav-ctrls-height: calc(68px + 2 * var(--map-nav-ctrls-gutter));

  display: flex;
  flex-direction: column;
  background-color: var(--mantine-color-body);
  height: calc(100% - var(--map-nav-ctrls-height));
  position: absolute;
  right: var(--map-nav-ctrls-gutter);
  z-index: 1;
  top: var(--map-nav-ctrls-gutter);
  gap: var(--mantine-spacing-sm);
  border-radius: var(--mantine-radius-sm);
  max-height: 300px;
}

.legendGradient {
  display: flex;
  flex: 1;
  justify-content: center;
}

.legendGradientBar {
  border-radius: 4px;
  height: 100%;
  min-width: 8px;
  width: 8px;
}

.gradientMarks {
  --gradient-font-size: 14px;

  display: flex;
  flex-direction: column;
  font-size: var(--gradient-font-size);
  height: calc(100% + var(--gradient-font-size));
  justify-content: space-between;
  position: relative;
  top: calc(-1 * 0.5 * var(--gradient-font-size));
}

.gradientMarkRoot {
  align-items: center;
  display: flex;
  position: relative;
}

.gradientMarkText {
  font-size: var(--mantine-font-size-xs);
  line-height: 1;
  position: relative;
}

.gradientMarkMark {
  background-color: currentColor;
  height: 1px;
  margin-right: 4px;
  width: 8px;
}

.Selector {
  width: 100%;
}

.TableHeader {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.HoverClick:hover {
  background-color: #e0e0e0;
}

.RowPadding {
  padding-left: 0.5rem;
}

.TransactionTitleColumn {
  color: #003166;
}

.RightRemoveButton {
  padding-left: 5rem;
}

.BigColumn {
  width: 50%;
}

.SpinnerWrapper {
  width: 100%;
  text-align: center;
  margin: 1rem auto;
}

.NoBorder {
  border-bottom: none;
  background-color: #ededed;
}

.SearchBar {
  width: 100%;
}

.SearchBarInput {
  height: 2.3rem;
}

.SearchBarMenu {
  max-height: 10rem;
}

.TinySpinner {
  width: 0.7rem;
  height: 0.7rem;
  margin: 0 0.5rem;
}

.SlimBox {
  width: 100%;
  height: 33%;
  position: relative;
  outline: 1px solid lightgray;
  background-color: white;
}

.Bar {
  position: absolute;
  width: 70%;
  left: 15%;
  bottom: 0;
  z-index: 1;
  background-color: #a6afbf;
  border: 1px solid #113063;
}

.SingleBox {
  margin-bottom: 0.2rem;
  height: 4rem;
  width: 100%;
  position: relative;
}

.SingleBox:hover {
  cursor: pointer;
  opacity: 0.6;
  z-index: 2;
}

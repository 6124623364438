.Wrapper {
  display: inline-block;
  position: relative;
}

.Children {
  cursor: pointer;
}

.Tooltip {
  background-color: white;
  display: none;
  position: absolute;
  z-index: 1;
}

.Right {
  right: 0;
}

.Left {
  left: 0;
}

.Visible {
  display: block;
}

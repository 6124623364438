.FieldCell {
  align-items: center;
  display: flex;
  overflow-wrap: anywhere;
}

.FieldThumbnail {
  position: relative;
  width: 40px;
}

.EditBoundariesIcon {
  position: absolute;
  font-size: 0.8rem;
  min-width: 2rem;
  left: 0.3rem;
  top: 0.3rem;
}

.FieldName {
  padding: 0.5rem;
  display: flex;
  flex-flow: column;
}

.FieldName a {
  color: #0f3162;
  text-decoration: none;
}

.FieldName a:hover {
  text-decoration: underline;
}

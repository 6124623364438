.DeliveredAtIcon {
  width: 1.5rem;
  height: 1.5rem;
  padding-left: 0.5rem;
  color: #b4b4b4;
}

.FieldRowWrapper {
  background-color: white;
}

.FieldRowWrapper:hover {
  background-color: #fcfcfc;
}

.Row {
  border-top: 1px solid #e4e4e4;
  align-items: flex-start;
  border-bottom: 0;
  padding: 0.5rem 0.3rem;
}

.SamplingPlanRow {
  align-items: flex-start;
  border-bottom: 0;
  border-top: 1px solid #f3f3f3;
  padding: 0.3rem;
}

.SamplingPlanCell a {
  text-decoration: none;
  color: #0f3162;
}

.SamplingPlanCell a:hover {
  text-decoration: underline;
}

.SamplingPlanCell > div {
  display: flex;
  flex-wrap: wrap;
}

.Spinner {
  width: 1rem;
  height: 1rem;
  margin: 0;
}

.TableCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.2rem;
}

.Center {
  text-align: center;
  padding: 0.25rem 0.1rem;
}

.Line {
  width: 6%;
  max-width: 2rem;
  height: 0.3rem;
  background-color: #003166;
  margin: auto 0;
  overflow: hidden;
}

.Tooltip {
  background-color: #fff;
  border: 1px solid #d0d9e2;
  border-radius: 4px;
  box-shadow:
    inset 0 2px 0 0 rgba(255, 255, 255, 0.05),
    0 0 8px 4px rgba(218, 218, 218, 0.26);
  color: #4a4a4a;
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  min-width: 10rem;
  padding: 0.25rem 0;
}

.TooltipCol {
  width: 5rem;
  align-items: flex-end;
  white-space: nowrap;
}

.ProductName {
  font-weight: bold;
}

@media print {
  .HideForPrint {
    display: none;
  }
  .TableCol > div {
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0.5rem 0.5rem 0;
  }
}

.PrimaryAction {
  font-size: 0.9rem;
  margin-right: 0.5rem;
}

.DoNotDisplay {
  display: none;
}

.ProgressStatus {
  margin-top: -1rem;
}

.StatusContainer {
  display: flex;
  justify-content: flex-start;
}

@media screen and (min-width: 75rem) {
  .OrdersStatus {
    padding: 0.25rem 1rem;
    justify-content: space-between;
    display: flex;
  }

  .OrdersStatus .StatusColon {
    display: none;
  }

  .OrdersStatus .StatusText {
    text-align: center;
  }

  .Status {
    width: 49%;
  }
}

@media screen and (max-width: 75rem) {
  .OrdersStatus {
    margin: 0;
    padding: 0.25rem 0.5rem;
    list-style-type: none;
  }

  .OrdersStatus .Line {
    display: none;
  }

  .OrdersStatus .StatusText {
    text-align: left;
  }

  .OrdersStatus .StatusText div {
    display: inline;
  }
}

.Status {
  padding: 0;
  color: #003166;
  margin-left: 1rem;
  font-size: 0.9rem;
  white-space: nowrap;
}

.Status:first-child {
  margin-left: 0;
}

.Status div:first-child {
  margin-bottom: 0.3rem;
}

.StatusBox {
  min-width: 11rem;
  outline: dashed 1.5px black;
  outline-offset: -1.5px;
}

.StatusBold {
  font-weight: 600;
}

.NotOrdered {
  color: #11306380;
}

.Wrapper {
  display: flex;
  flex-direction: column;
}

.FlexContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.SaveButtons {
  margin-top: 3rem;
  margin-left: auto;
}

.SpaceBetween {
  justify-content: space-between;
}

.MainAttributes {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.MainAttributes > * {
  margin-right: 1rem;
}

.MainAttributes > *:last-child {
  margin-right: 0;
}

.TextFields {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.TextFields > * {
  margin-right: 1rem;
}

.Table .TableRow {
  border: none;
}

.Table .TableCol {
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.Table .TableCol:first-child > div {
  margin-left: 0;
}

.CategorySelector {
  width: 15rem;
}

.AnalyticImage {
  width: 3.2rem;
  height: 3.2rem;
  overflow: hidden;
}

.AgencySelector {
  width: 13rem;
}

.Row {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  color: #0f3162;
}

.Column .Toggle {
  width: 4rem;
  margin-left: 2rem;
}

.CheckboxWrapper {
  min-width: 4.75rem;
  margin-left: 1.5rem;
  display: flex;
  justify-content: space-between;
}

.CheckboxWrapper .ZoneCounter {
  margin: 0.35rem 0 0 0.2rem;
  font-size: 0.9rem;
  font-weight: bold;
}

.Column:first-child {
  min-width: 5rem;
}

.SampleTiming {
  width: 14rem;
}

.ZoneCreation {
  width: 15rem;
}

.SideButton {
  margin-left: 0.5rem;
  min-width: 11rem;
}

.DensityWrapper {
  min-width: 16rem;
}

.Density {
  width: 5.5rem;
}

.ProductCategoryRow {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ProductSelector {
  width: 14rem;
}

.ReverseDropdown {
  bottom: 100%;
}

.Label {
  font-weight: bold;
}

.FormWrapper {
  flex-grow: 1;
  padding: 0.2rem 1.5rem 0;
}

.RowHeader {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

.Description {
  font-size: 0.7rem;
  color: #113063b2;
}

.BackButton {
  position: absolute;
  top: 0;
  left: 0;
  color: #0060ff;
  font-size: 0.9rem;
  cursor: pointer;
  transition: 0.25s;
}

.BackButton:hover {
  transform: translateY(-2px);
}

.BackButton:active,
.BackButton:focus {
  transform: translateY(2px);
}

.Hover:hover {
  cursor: pointer;
}

.ButtonSpinner {
  margin: 0 0 0 0.5rem;
  width: 0.6rem;
  height: 0.6rem;
}

.RedColor {
  color: red;
}

.ProductColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ProductRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
}

.ProductName {
  font-weight: bold;
}

.ProductMsg {
  font-style: italic;
  font-size: 0.8rem;
}

.ProductDisabled {
  color: #b7bbbf;
  cursor: auto;
}

.ProductDisabled:hover {
  transform: none;
}

.ProductDisabled:active,
.ProductDisabled:focus {
  transform: none;
}

.HighlightedColor {
  color: #0060ff;
}

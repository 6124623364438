.Wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}

.CircleSelector {
  margin: 0.3rem;
}

.Arrow {
  color: #b8c1d0;
}

.Arrow:hover {
  cursor: pointer;
  color: #003166;
}

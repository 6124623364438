.Divider {
  border: 1px solid #ececed;
  width: 100%;
  margin: 0.75rem 0 1rem;
}

.SmallSpinner {
  height: 1rem;
  margin: 0 auto;
  width: 1rem;
}

.AlertBox {
  margin: 1rem 0;
  width: 8rem;
  height: 7rem;
}

.AlertBox:hover {
  cursor: pointer;
}

.AlertString {
  text-align: center;
  margin-bottom: auto;
}

.Avatar {
  background-color: #e88484;
}

.Wrapper {
  color: #003166;
  width: 100%;
}

.Wrapper h1 {
  color: black;
  text-align: center;
  font-weight: 500;
}

.SubmitWrapper {
  text-align: center;
}

.AdjustmentLabel {
  margin: 1rem 0rem;
  width: 100%;
}

.Description {
  color: black;
  height: 5rem;
  resize: none;
}

.TransactionType {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.Amount {
  margin-top: 1rem;
  width: 45%;
}

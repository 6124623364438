.NewButton {
  font-size: 1rem;
}

.Blank {
  margin: 0 1rem;
}

.ExpandingColumn {
  flex: 1;
}

.FilterButton {
  align-items: center;
  color: #878484;
  justify-content: flex-start;
}

.FilterDropdown {
  width: 100%;
  margin-left: -1rem;
}

.Header > * {
  margin-right: 0.5rem;
}

.PaginationRow {
  background-color: white;
  bottom: 0;
  position: sticky;
  text-align: center;
}

.SpinnerWrapper {
  height: 40vh;
}

.Table {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 13rem);
  overflow-y: scroll;
  padding: 0rem 1rem;
}

.TableHeader {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 10;
}

.PlateSearchInput {
  width: 18rem;
}

.Link {
  color: #2f80ed;
  text-decoration: none;
}

.Link:hover {
  text-decoration: underline;
}

.Filter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  column-gap: 1rem;
  align-items: center;
  color: #113063;
  margin: 1rem 0;
}

.FilterLabel {
  font-size: 1.1rem;
}

.UnclickedRow:hover {
  background-color: #e0e0e0;
}

.ClickedRow {
  background-color: #e0e0e0;
}

.ClickedRow:hover {
  background-color: #c1c1c1;
}

.ExpandedBackground {
  border-bottom: none;
  background-color: #ededed;
}

.ExpandedCol > div {
  margin: 0.25rem 1rem 0.25rem 0;
}

.ExpandedHeader {
  font-weight: 700;
}

.ExpandedTable {
  border-bottom: none;
  background-color: #ededed;
  padding: 0.5rem 1rem;
}

.LabIdSearchBar {
  width: 7rem;
}

.Regulated {
  background-color: #e88484;
}

.Spinner {
  width: 1rem;
  height: 1rem;
}

.Batch {
  height: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
}

.Link {
  color: #2f80ed;
  text-decoration: none;
}

.Link:hover {
  text-decoration: underline;
}

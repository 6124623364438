.TableHeader {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  padding: 1px;
}

.Modal {
  background-color: white;
  left: 50%;
  padding: 2rem;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 60rem;
  height: 30rem;
}

.Overlay {
  background-color: gray;
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;
}

.DialogContent {
  text-align: left;
  margin: 1rem 10% 1.5rem 10%;
  overflow-y: scroll;
  height: 100%;
}

.Columns {
  display: flex;
  height: 60%;
}

.Column {
  flex: 50%;
  height: 80%;
  padding-right: 1rem;
}

.RangeInput {
  width: 5rem;
  margin-right: 2rem;
}

.PriceInput {
  width: 8rem;
  margin-right: 1rem;
}

.SearchBar {
  width: 25rem;
  margin-bottom: 2rem;
}

.RangeWithPrice {
  margin-bottom: 1rem;
}

.MaxValue {
  margin-bottom: 1rem;
}

.Tag {
  align-items: center;
  display: block;
  height: 1.5rem;
  margin-top: 0.5rem;
  min-width: 14rem;
  white-space: nowrap;
  color: #031166;
}

.Tag span:last-child {
  cursor: pointer;
  margin-left: 0.2rem;
}

.DialogButtons {
  text-align: right;
  bottom: 0;
  margin: 2rem;
}

.MarginLeft {
  margin-left: 1rem;
}
.EditCloseIcons {
  text-align: right;
}

.ShrinkedColumn {
  width: 11rem;
}

.Selector {
  width: 100%;
}

.Clickable {
  cursor: pointer;
  color: #0f3162;
}

.Clickable:hover {
  text-decoration: underline;
}

.TableHeader {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.RowHover {
  padding: 0 5px;
}

.RowHover:hover {
  background-color: #ededed;
}

.ColumnLeft {
  justify-content: flex-start;
}

.Button {
  font-size: 1rem;
}

.Buttons {
  display: inline-flex;
}

.Input {
  min-height: 100px;
  width: 100%;
  font-size: 1rem;
  margin: 1rem 0;
}

.Text {
  display: block;
  font-size: 1rem;
  margin: 1rem 0;
}

.Wrapper {
}

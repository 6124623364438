.Wrapper {
  padding: 22px 16px 34px;
  height: 100%;
  width: 100%;
}

.Container {
  height: 100%;
  vertical-align: top;
}

.Icon {
  font-size: 2.5rem;
  margin-bottom: 22px;
}

.Text {
  font-size: 1.5rem;
  font-weight: normal;
  text-align: center;
}

.FormTypeSelector {
  min-width: 13rem;
}

.Error {
  color: red;
}

.InputLabel {
  align-items: center;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.ValueLabel {
  align-items: top;
  display: flex;
  flex-flow: row;
  column-gap: 0.4rem;
}

.InputSelector {
  min-width: 10rem;
}

.ControlSelector {
  min-width: 13rem;
}

.Attribute {
  padding-left: 20%;
}

.Input,
.Textarea {
  --color: #031166;
  --background-color: #fff;
  --border-color: #aeb9c5;

  background-color: var(--background-color);
  border-radius: 0.25rem;
  border: solid 0.0625rem var(--border-color);
  box-sizing: border-box;
  color: var(--color);
  display: inline-block;
  font: inherit;
  margin: 0;
  padding: 0.5em 0.75em;
  width: 100%;
}

.Input:placeholder-shown,
.Textare:placeholder-shown {
  --color: #aeb9c5;
}

.Input[type='file'] {
  border: none;
  padding: 0;
}

.Input:focus,
.Textarea:focus {
  --border-color: #031166;

  outline: none;
}

.Input:disabled,
.Input[disabled],
.Textarea:disabled,
.Textarea[disabled] {
  cursor: not-allowed;
  background-color: #eee;
  opacity: 1;
}

.Round {
  border-radius: 1rem;
}

.Error {
  border-color: red;
}

.UnitsWrapper {
  position: relative;
}

.UnitsInInput {
  color: #99a5b1;
  position: absolute;
  top: 50%;
  right: 3rem;
  margin: auto;
  transform: translateY(-50%);
}

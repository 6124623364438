.Blank {
  margin: 0 1rem;
}

.ExpandingColumn {
  flex: 1;
}
.NewIndexSet {
  font-size: 1rem;
}

.Header {
  height: 2.3rem;
}

.SpinnerWrapper {
  height: 40vh;
}

.Table {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 13rem);
  overflow-y: scroll;
}

.TableHeader {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 10;
}

.Wrapper {
  background-color: white;
  flex: 1;
  padding: 1rem 1rem 0 2rem;
  position: relative;
}

.GoBack {
  font-size: 0.9rem;
  line-height: 0.5rem;
  height: 1.7rem;
  padding: 0 1.5rem;
  position: absolute;
  top: -0.9rem;
  left: 1rem;
}

.TableHeader {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.ShrinkedColumn {
  width: 12rem;
  text-align: right;
}

.DeleteIcon {
  color: #e74c3c;
  padding-right: 1rem;
}

.DeleteIcon svg {
  cursor: pointer;
}

.FullWidth {
  width: 40rem;
}

.RemoveCancel {
  text-align: right;
}

.RemoveCancel button {
  margin-right: 1rem;
}

.SmallSpinner {
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
}

.TitleContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.Title {
  align-self: flex-end;
  margin: 0;
}

.TitleButtons > * {
  margin-left: 0.5rem;
}

.Divider {
  border: 1px solid #ececed;
  width: 100%;
  margin: 0.75rem 0 2rem;
}

.LeftAligned {
  justify-content: left;
  align-items: center;
  font-size: 0.85rem;
}

.Clickable {
  cursor: pointer;
}

.Ellipsis {
  cursor: pointer;
  width: 1rem;
}

.OpenPlans {
  text-align: center;
  font-weight: 500;
  font-style: italic;
  letter-spacing: 0.25px;
  border-bottom: 1px solid #ececed;
}

.Spinner {
  height: 3rem;
  width: 3rem;
}

.Table {
  flex: 1;
  font-size: 0.9rem;
  color: #0f3161;
}

.TableHeader {
  z-index: 1;
}

.Tooltip {
  background-color: #fff;
  border: 1px solid #d0d9e2;
  border-radius: 4px;
  box-shadow:
    inset 0 2px 0 0 rgba(255, 255, 255, 0.05),
    0 0 8px 4px rgba(218, 218, 218, 0.26);
  color: #4a4a4a;
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  min-width: 10rem;
  padding: 0.25rem 0;
}

.PendingDelivery {
  color: #7dc11b;
}

.HeaderContent {
  font-weight: bold;
}

.OperationButton {
  margin-left: 0.5rem;
}

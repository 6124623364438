.DownloadButton {
  width: 2.2rem;
  min-width: 0;
  height: 2.2rem;
  right: 0;
  position: absolute;
  top: 0;
}

.AcreCommit {
  margin-right: 1rem;
}

.ButtonSpinner {
  margin: 0;
  width: 0.5rem;
  height: 0.5rem;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  color: #0f3162;
}

.Button {
  font-size: 0.9rem;
  margin-left: 0.2rem;
}

.HeaderButtons {
  margin: 0.9rem 0 0.6rem 0;
}

.ButtonToolbar {
  display: flex;
  justify-content: space-evenly;
  margin: auto;
  margin-top: 2rem;
  width: 70%;
}

.Modal {
  background-color: white;
  left: 50%;
  padding: 3rem;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 201;
  min-width: 27rem;
  text-align: center;
}

.Overlay {
  background-color: gray;
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 200;
}

.InputLine {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  width: 38rem;
}

.InputLine span {
  max-width: 8rem;
  overflow: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 8rem;
}

.InputLine span:first-child {
  text-align: left;
  max-width: 13rem;
  width: 13rem;
}

.InputLine input {
  width: 6rem;
}

.InputLine .Bold {
  font-weight: bold;
}

.Disclaimer {
  text-align: left;
  width: 35rem;
}

.PurchaseLink {
  color: #3300ff;
  cursor: pointer;
}

.CancelText {
  margin-bottom: 1rem;
  text-align: left;
  width: 25rem;
}

.CancelToolbar {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.Tooltip {
  background-color: #fff;
  border: 1px solid #d0d9e2;
  border-radius: 4px;
  box-shadow:
    inset 0 2px 0 0 rgba(255, 255, 255, 0.05),
    0 0 8px 4px rgba(218, 218, 218, 0.26);
  color: #4a4a4a;
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  min-width: 10rem;
  padding: 0.25rem 0;
}

.TooltipWrapper {
  position: relative;
}

.GreenButton {
  color: #7dc11b;
}

.GreenButton:hover {
  color: white;
}

.RedButton {
  color: red;
}

.RedButton:hover {
  color: white;
}

@media print {
  .HideForPrint {
    display: none;
    visibility: hidden;
  }
}

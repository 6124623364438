.Label {
  display: inline-block;
  position: relative;
}

.Icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: silver;
}

.Label .InputLeft {
  padding-left: calc(1em + 1.5rem);
}

.Label .InputRight {
  padding-right: calc(1em + 1.5rem);
}

.Left {
  left: 1rem;
}

.Right {
  right: 1rem;
}

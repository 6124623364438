.Wrapper {
  font-size: 1.2rem;
  color: #0f3162;
}

.MarginBottom {
  margin-bottom: 2rem;
}

.Spinner {
  text-align: center;
  margin: 10rem auto auto;
}

.SmallSpinner {
  height: 1rem;
  width: 1rem;
  margin: auto 1rem;
}

.Overlay {
  background-color: gray;
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 200;
}

.ErrorMessage {
  height: 5rem;
  color: red;
}

.Street {
  width: 40rem;
  margin-bottom: 1rem;
}

.City {
  width: 20rem;
}

.Zip {
  width: 10rem;
}

.ModalWrapper {
  text-align: left;
  height: 100%;
  position: relative;
}

.MarginRight {
  margin-right: 1rem;
}

.Dialog {
  padding: 0 1.5rem 1.5rem;
}

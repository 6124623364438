.ErrorText {
  color: #d72e3d;
  font-size: 1rem;
}

.Form {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0;
}

.Form > * {
  margin-bottom: 1rem;
}

.Hidden {
  display: none;
}

.InputError {
  border-color: #d72e3d;
}

.Label {
  display: inline-block;
  max-width: 15rem;
}

.Note {
  font-size: 0.875rem;
  font-style: italic;
  margin-top: -0.5rem;
}

.Spinner {
  border: 3px solid rgba(0, 49, 102, 0.3);
  border-top-color: #ffffff;
  height: 1rem;
  margin: 0 auto;
  width: 1rem;
}

.Wrapper {
  margin-top: 3.5rem;
}

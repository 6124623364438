.Disabled {
  cursor: not-allowed;
  opacity: 0.65;
  box-shadow: none;
}

.DropdownIcon {
  width: 1em;
  height: 1em;
}

.Icon {
  margin-right: 1em;
  height: 1em;
  display: inline;
  vertical-align: bottom;
}

.Menu {
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #d0d9e2;
  box-shadow:
    inset 0 2px 0 0 rgba(255, 255, 255, 0.05),
    0 0 8px 4px rgba(218, 218, 218, 0.26);
  color: #4a4a4a;
  display: flex;
  flex-direction: column;
  font-size: inherit;
  max-height: 15em;
  overflow: auto;
  padding: 0.25rem 0;
  position: absolute;
  width: 100%;
  z-index: 999;
}

.Option {
  margin: 0;
}

.OptionInactive {
  cursor: default;
}

.OptionInactive:hover {
  background-color: #ffffff;
  color: #4a4a4a;
}

.OptionSelected {
  background-color: #ececed;
}

.RequiredError {
  box-shadow: 4px 4px 4px 1px rgba(255, 0, 0, 0.25);
}

.Selector {
  align-items: center;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #d0d9e2;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 0.5em 1em;
  height: 100%;
}

.Selector > .Title {
  margin-right: 1em;
}

.Title {
  font-size: inherit;
  line-height: normal;
  display: inline;
  flex-grow: 1;
}

.HideOverflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Wrapper {
  box-sizing: border-box;
  display: inline-block;
  font-size: inherit;
  position: relative;
  vertical-align: middle;
}

.Wrapper * {
  box-sizing: border-box;
}

@media print {
  .DropdownIcon {
    display: none;
    visibility: hidden;
  }
}

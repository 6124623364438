.Wrapper {
  display: flex;
  flex-direction: column;
  width: 45rem;
  padding: 0 1rem 1rem;
  margin-top: -2rem;
  text-align: center;
  color: #113063;
  row-gap: 1rem;
}

.Title {
  font-weight: 600;
  font-size: 1.7rem;
  color: black;
}

.Grid {
  box-sizing: border-box;
  display: grid;
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
  grid-template-columns: repeat(3, 1fr);
  margin: 1rem 0;
  text-align: left;
}

.Selector {
  width: 100%;
}

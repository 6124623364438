.Header {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  align-items: center;
}

.InfoText {
  color: #878484;
  text-align: left;
  font-size: 0.8rem;
  max-width: 18rem;
}

.Header {
  margin: 0;
  margin-bottom: 1rem;
}

.Header input {
  height: 2.5rem;
}

.FieldNameInput {
  width: 20rem;
}

.TitleText {
  color: #0f3162;
  font-size: 2rem;
  font-weight: lighter;
  margin-top: 0;
}

.Toolbar {
  display: flex;
  align-items: center;
}

.Toolbar div:not(:last-child) {
  margin-right: 0.5rem;
}

.Toolbar button:not(:last-child) {
  margin-right: 0.5rem;
}

.LoadingOverlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;
}

.Spinner {
  margin-top: 40vh;
}

.Input {
  width: 8rem;
}

.InputRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 1rem;
  margin: 1rem;
  white-space: nowrap;
  align-items: flex-end;
}

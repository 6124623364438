.RiskLevelRow {
  width: 20rem;
  border-radius: 0.25rem;
  height: 1.5rem;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.RiskLevel {
  width: 8rem;
}

.LowRisk {
  background-color: #80dba3;
}

.ModerateRisk {
  background-color: #ffbd80;
}

.HighRisk {
  background-color: #e68080;
}

@media print {
  .HideForPrint {
    display: none;
    visibility: hidden;
  }
}

.Row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 2rem;
}

.Column {
  width: 48%;
  min-width: 30rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 1rem;
  margin-bottom: 1rem;
}

.SummaryHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

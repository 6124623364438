.ShrinkedColumn {
  width: 12rem;
  text-align: right;
}

.TableHeader {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

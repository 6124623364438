.FiltersWrapper {
  padding-bottom: 1rem;
  border-bottom: 3px solid #ececed;
  justify-content: space-between;
  display: flex;
}

.FiltersWrapper > div:first-child {
  width: 100%;
}

.SearchBar {
  width: 16rem;
}

.MapIcon {
  display: flex;
  margin-top: auto;
  margin-bottom: 0;
  margin-left: 1rem;
}

.SearchInput {
  width: 20rem;
  text-align: center;
}

.SmallSpinnerWrapper {
  display: inline-block;
  position: relative;
  top: 0.3rem;
  width: 2rem;
  height: 2rem;
  text-align: center;
}

.SmallSpinner {
  height: 1rem;
  width: 1rem;
}

.PlanLen {
  margin: auto 0;
}

.OpenPaymentsLink {
  color: #0068ff;
  display: block;
  font-size: 0.7rem;
  text-decoration: none;
}

.OpenPaymentsLink:hover {
  text-decoration: underline;
}

.InvoicesWrapper h2 {
  font-weight: bold;
  color: #003166;
}

.HeaderWithButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.AddAdjustments {
  margin-top: 1rem;
}

.MarginRight {
  margin-right: 1rem;
}

.ErrorText {
  color: red;
}

.Spinner {
  animation: spin 1s ease-in-out infinite;
  border: 3px solid rgba(137, 137, 137, 0.3);
  border-radius: 50%;
  border-top-color: #033368;
  height: 5rem;
  margin: 0;
  width: 5rem;
}

.Wrapper {
  display: inline-block;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.Fill {
  flex: 1; /* occupies remaining height/width of the parent flex */
  display: flex;
  align-items: center;
  justify-content: center;
}

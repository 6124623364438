.Form {
  margin-bottom: 1rem;
}

.Footer {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.Footer button {
  margin-bottom: 1rem;
}

.Spinner {
  height: 0.5rem;
  margin: auto;
  width: 0.5rem;
}

.SubmitButton {
  min-width: 3rem;
}

.Error {
  color: red;
}

.Wrapper {
  margin: 1rem;
  background-color: white;
  padding: 1rem;
  color: #0f3162;
}

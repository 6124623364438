.FieldMap {
  margin: 1rem 0;
  height: 18rem;
  justify-content: center;
}

.MapWrapper {
  width: 100%;
  height: 15rem;
}

.MissingData {
  border: 1px dashed;
}

.Dialog {
  padding: 0 1.5rem 1.5rem;
}

.Title {
  margin: 0 0 2rem;
  text-align: center;
}

.Description {
  margin-bottom: 4rem;
  text-align: center;
}

.Center {
  text-align: center;
}

.Submit {
  margin-top: 2rem;
}

.FormControlContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
}

.Error {
  color: red;
  margin: 1rem 0;
}

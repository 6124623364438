.Container > * {
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
}

.Vertical {
  display: flex;
  flex-direction: column;
}

.Vertical > * {
  margin-top: 1rem;
  margin-right: 0;
  margin-bottom: 0;
}

.Vertical > *:first-child {
  margin-top: 0;
}

.Form {
  display: flex;
  flex-direction: column;
}

.Form label {
  margin-bottom: 1rem;
}

.Submit {
  margin-left: 1rem;
}

.Wrapper {
  color: #003166;
  display: flex;
  flex-direction: column;
  margin-top: 10%;
  text-align: center;
}

.Container {
  text-align: center;
  max-width: 768px;
  margin: 0 auto;
}

.Logo {
  min-height: 100%;
  max-width: 25rem;
  margin: 7% auto 2rem auto;
}

.Map {
  flex: 1;
  height: 70vh;
  min-height: 200px;
  margin-top: 1rem;
  position: relative;
}

.Navigation {
  bottom: 2rem;
  position: absolute;
  right: 1rem;
}

.Recenter {
  position: absolute;
  bottom: 2rem;
  right: 4rem;
}

.CluButtonsContainer {
  display: flex;
  gap: 0.6rem;
  position: absolute;
  top: 6rem;
  right: 0.65rem;
}

.ReloadSpinner {
  height: 2rem;
  width: 2rem;
}

.InstructionsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 2rem;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 30rem;
  height: auto;
  background-color: #204362cc;
  border-radius: 5px;
  padding: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.InstructionText {
  color: white;
  text-align: center;
}

.Header {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  align-items: center;
}

.Input {
  width: 8rem;
  height: 2.5rem;
  text-align: right;
}

.Wrapper {
  color: #003166;
  position: absolute;
  font-weight: normal;
  z-index: 105;
  cursor: default;
  min-width: 30rem;
  max-width: 60rem;
}

.CurrentRec {
  background-color: white;
  border-radius: 0.3rem;
  border: solid 1px #003166;
  padding: 0 1rem;
}

.PastRecs {
  background-color: white;
  border-radius: 0.3rem;
  border: solid 1px #003166;
}

.PastRecRow {
  border-bottom: solid 1px darkgrey;
  color: grey;
  margin: 0 1rem;
}

.PastRecRow:last-child {
  border-bottom: 0;
}

.RecommendationRow {
  margin: 1rem 0 0.5rem 0;
  cursor: pointer;
}

.TextInput {
  margin-left: 0.5rem;
}

.CurrButtonsRow {
  margin: 1rem 0 0.6rem 0.5rem;
}

.PastButtonsRow {
  display: block;
  text-align: right;
  margin: 0 0.5rem 0.7rem 0;
}

.RemoveButton {
  margin-left: 1rem;
  color: red;
  cursor: pointer;
}

.RemoveButton:hover {
  color: #b60000;
}

.Popup {
  color: #0f3162;
  display: flex;
  flex-direction: column;
  max-width: 20rem;
}

.TooltipTextItalic {
  margin-top: 1rem;
  font-weight: normal;
  font-style: italic;
}

.Collection {
  padding: 1rem 1.5rem;
}

.CollectionRow {
  padding: 0.5rem;
}

.CollectionRow:hover,
.CollectionRowSelected {
  background-color: #cfd6e0;
  cursor: pointer;
}

.DisableIcon {
  cursor: pointer;
  margin-right: 1rem;
}

.PaddingLeft {
  padding-left: 0.5rem;
}

.ShrinkedColumn {
  width: 10rem;
  text-align: center;
}

.InnerRow {
  .data-with-row-border & {
    border-bottom: 1px solid #ebebeb;
  }
  background-color: #ebebeb;
  padding-left: 0.5rem;
}

.AgencyName:hover {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: #0f3161;
}

.ChevronDiv:hover {
  cursor: pointer;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  width: 45rem;
  padding: 0 1rem 1rem;
  margin-top: -2rem;
  text-align: center;
  color: #113063;
  row-gap: 1rem;
}

.Title {
  font-weight: 600;
  font-size: 1.7rem;
}

.Grid {
  box-sizing: border-box;
  display: grid;
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
  grid-template-columns: repeat(3, 1fr);
  margin: 1rem 0;
  text-align: left;
}

.Selector {
  width: 100%;
}

.NoAgenciesExist {
  display: flex;
  justify-content: center;
  margin: 1rem auto;
  width: 100%;
}

.TinySpinner {
  width: 0.5rem;
  height: 0.5rem;
  margin: 0 0.5rem;
}

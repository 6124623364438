.Button {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
}

.DropdownMenu {
  background-color: white;
  border-radius: 4px;
  border: 1px solid #d0d9e2;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.DropdownMenu > * {
  margin-bottom: 0.25rem;
  text-align: left;
}

.DropdownMenu > *:last-child {
  margin-bottom: 0;
}

.Icon {
  margin-left: auto;
  margin-right: -0.5em;
}

.Title {
  margin-right: 0.5rem;
}

.Wrapper {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
}

.Title {
  color: #0f3162;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.2;
}

.H1 {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.2;
}

.H2 {
  font-style: normal;
  font-weight: normal;
  line-height: 1.2;
}

.H3 {
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.2;
}

.H4 {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: bold;
  line-height: 1.2;
}

.H5 {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: bold;
  line-height: 1.2;
}

.H6 {
  font-size: 1rem;
  font-style: normal;
  font-weight: bold;
  line-height: 1.2;
}

.Thick {
  border-bottom: 3px solid #ececed;
}

.Thin {
  border-bottom: 1px solid #ececed;
}

.Toolbar {
  flex: 1;
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.Toolbar > button {
  margin-right: 1rem;
}

.Toolbar > div {
  margin-right: 1rem;
}

.Toolbar > button:last-child {
  margin-right: 0;
}

.Wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 1rem 0 1rem 0;
  padding: 0 0 1rem 0;
}

.None {
  margin: 1rem 0 0 0;
  padding: 0;
}

@media print {
  .Wrapper {
    margin: 1rem 0;
    padding: 0;
  }
  .Thin {
    border: none;
  }
  .Thick {
    border: none;
  }
}

.PitchList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.PitchList svg {
  font-size: 1.5em;
}

.Timeline svg {
  font-size: 1.1em;
}

.MenuBar {
  display: flex;
  justify-content: space-between;
}

.Toolbar {
  height: 2.5rem;
  display: flex;
  align-items: flex-end;
}

.SearchBar {
  width: 13rem;
  transition: 0.25s;
}

.SearchBar:hover,
.SearchBar:focus-within {
  width: 20rem;
}

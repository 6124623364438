.Overlay {
  background-color: rgba(33, 33, 33, 0.5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  overflow: hidden;
  z-index: 100;
  height: 100vh;
  width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;
}

.noScroll {
  overflow: hidden;
}

.Wrapper {
  background-color: white;
  z-index: 101;
  position: relative;
  text-align: left;
}

.InnerWrapper {
  padding: 0 1.5rem 1rem 1.5rem;
  max-width: 50rem;
}

.Toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
}

.Close {
  font-size: 0.75rem;
}

.Pagination {
  text-align: center;
}

.NoPaymentsWrapper {
  align-items: center;
  text-align: center;
  color: #0f3162;
  display: flex;
  flex-flow: column;
  flex: 1;
  padding: 1rem 1rem 0 2rem;
  margin-top: 3rem;
}

.HoverClick:hover {
  background-color: #ededed;
}

.Tag {
  align-items: center;
  border: 1px solid #003166;
  border-radius: 0.25rem;
  display: flex;
  height: 1.5rem;
  justify-content: space-between;
  margin-right: 1rem;
  margin-top: 0.5rem;
  max-width: 8rem;
  padding: 0 1rem;
  cursor: pointer;
}

.Tag span {
  white-space: nowrap;
}

.Tag span:last-child {
  margin-left: 0.5rem;
}

.SelectedTag {
  background-color: #0f3162;
  color: white;
}

.Tags {
  display: flex;
}

.SearchBar {
  max-width: 15rem;
}

.Wrapper {
  /* override NavBarLayout margins */
  margin: 0 -8.81% -1rem;
  width: 117.616%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.MenuWrapper {
  margin-top: -1rem;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.PanelContainerWrapper {
  width: 40%;
  min-width: 26rem;
  max-width: 30rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.MapField {
  width: 60%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.Controls {
  display: grid;
  right: 1rem;
  position: absolute;
  top: 1rem;
  grid-auto-flow: row;
  grid-template-columns: max-content;
}

.Controls button {
  margin-bottom: 0.25rem;
}

.Controls button:last-child {
  margin-bottom: 0;
}

.Merge {
  margin-right: 1rem;
}

.DeleteButton {
  margin-top: 1rem;
}

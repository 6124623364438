.EditRecommendation {
  width: 60%;
  z-index: 103;
  left: 20%;
  min-width: 30rem;
  position: absolute;
  top: 15rem;
}

.Overlay {
  background-color: gray;
  height: 100%;
  left: 0;
  opacity: 0.7;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 102;
}

@media print {
  .HideForPrint {
    display: none;
    visibility: hidden;
  }

  .SingleWrapper {
    display: grid;
    grid-template: 1fr / 1fr;
    overflow-y: visible;
  }

  .AccordionPrint {
    display: flex;
    height: 70rem;
    page-break-inside: avoid;
    page-break-before: auto;
    page-break-after: auto;
  }
}

.Header {
  display: flex;
  flex-direction: row;
  align-items: top;
  column-gap: 0.5rem;
}

.SubmitButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.75rem;
}

.Cycles {
  height: 2rem;
}

.Input {
  width: 10rem;
  font-size: 1rem;
}

.SmallSpinner {
  width: 1rem;
  height: 1rem;
}

.Text {
  margin: 1rem 1rem 0 1rem;
}

.NotesInput {
  width: 100%;
}

.Divider {
  border: none;
  border-top: 1px solid #033368;
  width: 100%;
}

.EmailInput {
  cursor: not-allowed;
}

.FormWrapper {
  margin-left: 10%;
  width: 30%;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 90%;
}

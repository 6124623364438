.Legend {
  cursor: default;
  position: fixed;
  top: 14rem;
  left: 0.5rem;
  overflow-y: auto;
}

.Crop:hover {
  cursor: pointer;
}

.lowRisk {
  background: #80dba3;
}

.highRisk {
  background: #e68080;
}

.moderateRisk {
  background: #ffbd80;
}

.pending {
  background: #ffffff;
  outline: dashed 1px #003166;
  outline-offset: -1.5px;
}

.notAnalyzed {
  background: #aaaeb2;
}

.notDetected {
  background: #ffffff;
  outline: solid 1px #003166;
  outline-offset: -1.5px;
  font-size: 0.5rem;
  padding-top: 0.1rem;
}

@media print {
  .Legend {
    display: none;
  }
}

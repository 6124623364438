.Spinner {
  width: 1rem;
  height: 1rem;
}

.NoLabels {
  height: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
}

.Link {
  color: #2f80ed;
  text-decoration: none;
}

.Link:hover {
  text-decoration: underline;
}

.Row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 1rem;
}

.Input {
  width: 15rem;
}

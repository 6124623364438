.Container {
  color: #0f3162;
  display: flex;
  flex-direction: column;
}

.CenterLabel {
  text-align: center;
  border-bottom: 1px solid #ececed;
  width: 100%;
  margin: auto;
}

.Label {
  font-size: 20px;
  line-height: 1.2;
  color: #031166;
  margin-bottom: 1rem;
  max-width: 40rem;
}

.TableWrapper {
  display: block;
  text-align: center;
  width: 60rem;
  margin: auto;
}

.Logo {
  height: 5rem;
  padding: 0.5rem;
  max-width: 9rem;
  height: auto;
}

.LogoColumn {
  width: 11rem;
}

.SmallSpinner {
  margin: auto 0.5rem;
  height: 1rem;
  width: 1rem;
}

.StickyHeader {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 50;
  border-bottom: 1px solid #ececed;
}

.PanelWrapper {
  flex-grow: 1;
  padding: 0 1.5rem 0;
  overflow-y: scroll;
  height: 85vh;
  color: #113063;
}

.SelectorRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.BackButton {
  color: #0060ff;
  cursor: pointer;
}

.Selector {
  margin: 1rem 0;
  width: 15rem;
}

.GoBack {
  height: 1.6rem;
}

.DownloadButton {
  margin: 2.3rem 0.2rem;
  align-items: center;
  font-size: 0.9rem;
  width: 2.3rem;
  right: 0.3rem;
}

.SmallSpinner {
  height: 1.15rem;
  width: 1.15rem;
}

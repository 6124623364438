.Wrapper {
  color: #003166;
  width: 100%;
}

.Wrapper h1 {
  color: black;
  text-align: center;
  font-weight: 500;
}

.Body {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.PaymentInfo {
  margin: 2rem auto auto auto;
  width: 85%;
}

.SubmitWrapper {
  margin: 1rem 0;
  text-align: center;
}

.FormColumn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.FieldInput {
  font-weight: bold;
}

.Selector {
  width: 10rem;
}

.SmallSpinner {
  margin-left: 1rem;
  height: 1rem;
  width: 1rem;
}

.MarginLeft {
  margin-left: 1rem;
}

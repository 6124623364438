.BannerWrapper {
  border-radius: 4px;
  background-color: red;
  color: white;
}

.BannerWrapperWarn {
  background-color: orange;
  color: black;
}

.Row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.BannerHeader {
  margin: 0 0.5rem;
  padding: 0.5rem;
}

.BannerHeadline {
  display: inline-block;
  padding-left: 0.5em;
}

.BannerHeadline p:first-child {
  display: inline-block;
  font-weight: bold;
  padding-right: 1rem;
  margin: 0;
}

.BannerHeader p:last-child {
  display: inline-block;
  font-weight: normal;
  margin: 0;
}

.PrescriptionWrapper {
  align-items: center;
  border-top: 1px solid rgba(17, 48, 99, 0.1);
  color: #113063;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  font-size: 0.95rem;
}

.SmallSpinner {
  height: 1rem;
  margin: 0 auto;
  width: 1rem;
}

.PrescriptionRowItem {
  height: 18rem;
  width: 52%;
}

.Map {
  height: 18rem;
  width: 46%;
}

.PrescriptionSummaryRow {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  width: 100%;
}

.PrescriptionSummaryRow:not(:first-child) span {
  width: 33%;
}

.MapWrapper {
  flex: 0 0 auto;
  height: 18rem;
}

.ButtonWrapper {
  display: flex;
  justify-content: flex-end;
  column-gap: 1rem;
  width: 46%;
}

.BulkWrapper {
  display: flex;
  justify-content: space-between;
  width: 52%;
}

.MenuBar {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 1rem;
}

.AdjacentButtons {
  margin-left: 4rem;
}

.Modal {
  text-align: center;
}

.MapbookBar {
  background-color: #ededed;
  color: #113063;
  height: 2rem;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  margin-top: 2rem;
  font-weight: bold;
}

.Red {
  color: #e68080;
}

.SelectExportType {
  width: 20rem;
}

@media print {
  .PrescriptionWrapper {
    font-size: 0.8rem;
  }
}

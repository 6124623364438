.Wrapper {
  /* override NavBarLayout margins */
  margin: 0 -8.81% -1rem;
  width: 117.616%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.MenuWrapper {
  margin-top: -1rem;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.PanelContainerWrapper {
  width: 40%;
  min-width: 26rem;
  max-width: 30rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.MapField {
  width: 60%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.NoData {
  text-align: center;
  margin: auto;
  padding-top: 15rem;
  background-color: lightgray;
  opacity: 0.8;
  height: 100%;
  width: 100%;
}

@media print {
  .HideForPrint {
    display: none;
    visibility: hidden;
  }

  .MenuWrapper {
    display: block;
    margin: auto 10rem;
  }

  .PanelContainerWrapper {
    width: 100%;
    display: block;
    min-height: 140rem;
    /* page-break-after: always; */
  }

  .MapField {
    width: 60rem;
    height: 50rem;
    top: 0;
    display: block;
    margin: 10rem auto auto;
    page-break-after: always;
  }
}

.LinkWrapper {
  align-items: center;
  display: flex;
  height: 3rem;
}

.LinkBottomBorder {
  border-bottom: 2px solid#003166;
  height: calc(3rem - 4px);
  padding-top: 2px;
}

.Logo {
  height: 2.1rem;
  margin-left: 0;
  display: block;
  padding-left: 1rem;
  padding-right: 1rem;
}

.Plus {
  width: 13px;
  height: 14px;
}

.AgencyLogo {
  height: 2.3rem;
  margin-left: 0;
  display: block;
  padding-left: 1rem;
  padding-right: 1rem;
}

.Settings {
  height: 2rem;
}

.Wrapper {
  align-items: center;
  text-align: center;
  justify-content: space-between;
  background-color: #ffffff;
  box-shadow: 0 -30px 40px 10px rgba(30, 30, 30, 0.5);
  display: flex;
  height: 3rem;
  margin-bottom: 1rem;
  padding-right: 2rem;
  z-index: 150;
}

@media print {
  .Wrapper {
    display: none;
  }
}

.Wrapper > span > a {
  border-left: 1px solid rgba(43, 48, 52, 0.1);
  color: #878484;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  text-decoration: none;
}

.Wrapper li > a {
  border-left: 1px solid rgba(43, 48, 52, 0.1);
  color: #878484;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  text-decoration: none;
}

.Wrapper .ActiveLink {
  color: #003166;
}

.DropdownButton {
  border-left: 1px solid rgba(43, 48, 52, 0.1);
  font-size: 0.875rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  z-index: 2;
}

.Dropdown {
  margin-left: auto;
  margin-right: 1rem;
  z-index: 2;
  min-width: 11rem;
}

.DropdownSeparator {
  background-color: transparent;
  cursor: default;
  outline: none;

  display: inline-block;
  text-decoration: none;
  box-sizing: border-box;

  color: #003166;
  font-weight: bold;
  line-height: 1.15;
  margin: 0em 1em;
  padding: 1em 0em 0.5em 0em;

  border-top: 1px solid #5e6871;
}

.Grid {
  margin-top: 1rem;
}

.GridRow {
  display: flex;
  flex-direction: row;
}

.GridCell {
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Well {
  align-items: center;
  background-color: white;
  border: 1px solid black;
  box-sizing: border-box;
  display: flex;
  font-size: 0.7rem;
  height: 2.4rem;
  justify-content: center;
  padding: 0.25rem;
  width: 2.4rem;
}

.Clickable {
  cursor: pointer;
}

.WellWithControl {
  background-color: black;
}

.SoftHighlight {
  background-color: rgb(252, 246, 204);
}

.StrongHighlight {
  background-color: #ffdd4a;
}

.WellFilled {
  background-color: grey;
}

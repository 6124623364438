.StickyHeader {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
}

.RecBubble {
  border: 2px solid #113063;
  border-radius: 4px;
  color: #113063;
  margin: 0.5rem 0 0 0;
  padding: 0.8rem 0.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  position: -webkit-sticky;
  position: sticky;
  top: 3rem;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 25rem;
  padding: 0 1rem 1rem;
  margin-top: -2rem;
  text-align: center;
}

.Title {
  font-weight: 600;
  font-size: 1.7rem;
}

.SubmitButton {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  justify-content: center;
}

.Actions {
  min-width: 12rem;
}

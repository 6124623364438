.AlertsModal {
  background-color: white;
  border: 2px solid #003166;
  border-radius: 4px;
  left: 50%;
  padding: 1rem;
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50vh;
  z-index: 201;
  width: auto;
  text-align: left;
  overflow-y: auto;
  height: auto;
}

.Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding-bottom: 0.5rem;
}

.AlertRow {
  margin: 0.5rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.AlertRow p {
  margin: 0;
  padding: 0;
  color: #0f3162;
  margin-right: 1rem;
}

.AlertRow input {
  width: 1.5rem;
  height: 1.5rem;
}

.AlertCheckbox {
  width: auto;
  height: auto;
}

.AlertButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.AlertButtonCancel {
  width: auto;
  height: auto;
}

.AlertButtonConfirm {
  width: auto;
  height: auto;
  background-color: #003166;
  color: #ffffff;
}

.Overlay {
  background-color: gray;
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;
}

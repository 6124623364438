.OpenPaymentsLink {
  color: #0068ff;
  display: block;
  font-size: 0.7rem;
  text-decoration: none;
}

.OpenPaymentsLink:hover {
  text-decoration: underline;
}

.InvoicesWrapper h2 {
  font-weight: bold;
  color: #003166;
}

.RowPadding {
  padding-left: 0.5rem;
}

.PaymentInfo {
  color: #003166;
}

.PaymentInfo span {
  font-weight: bold;
}

.HeaderWithButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

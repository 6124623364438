.Accordion {
  width: 100%;
  padding-top: 1rem;
  min-width: 20rem;
}

.BottomBorder {
  border-bottom: 0.2rem solid #ececed;
}

.AccordionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-bottom: 0.8rem;
  z-index: 2;
  position: relative;
}

.AccordionTitle {
  font-weight: 600;
  font-stretch: wider;
  font-size: 1.2rem;
  color: #113063;
}

.AccordionButton {
  font-weight: 600;
  font-stretch: wider;
  color: #0060ff;
  transition: 0.25s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.AccordionButton:hover {
  transform: translateY(-2px);
}

.AccordionHeader:active .AccordionButton,
.AccordionHeader:focus .AccordionButton {
  transform: translateY(2px);
}

.AccordionPlaceholder {
  font-weight: 600;
  color: #113063b2;
  padding-top: 0.5rem;
}

.Open .PlaceholderInner {
  max-height: 200rem;
  transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition-duration: 250ms;
  transition-property: max-height;
}

.Open .PlaceholderContent {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 200ms;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  transition: opacity transform;
}

.PlaceholderInner {
  max-height: 0;
  overflow: hidden;
  text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  transition-duration: 250ms;
  transition-property: max-height;
  z-index: 1;
  position: relative;
}

.PlaceholderContent {
  opacity: 0;
  transform: translateY(-1rem);
  transition-timing-function: linear, ease;
  transition-duration: 100ms;
  transition: opacity transform;
  transition-delay: 250ms;
  padding-bottom: 0.8rem;
}

.Open .BodyInner {
  max-height: 200rem;
  overflow: visible;
  transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition-duration: 250ms;
  transition-property: max-height;
}

.Open .BodyContent {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 200ms;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  transition: opacity transform;
}

.BodyInner {
  max-height: 0;
  overflow: hidden;
  text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  transition-duration: 250ms;
  transition-property: max-height;
  z-index: 3;
  position: relative;
}

.BodyContent {
  opacity: 0;
  transform: translateY(-1rem);
  transition-timing-function: linear, ease;
  transition-duration: 100ms;
  transition: opacity transform;
  transition-delay: 250ms;
  padding-bottom: 0.8rem;
}

.DropDownIcon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  stroke-width: 0;
  color: #113063;
}

.Flipped {
  transform: rotate(180deg);
}

@media print {
  .AccordionButton {
    display: none;
    visibility: hidden;
  }
}

.Wrapper {
  padding: 0.5rem;
  width: 26rem;
  height: 26rem;
}

.CustomYTick {
  word-wrap: break-word;
}

.TitleName {
  width: 100%;
  margin-bottom: 1rem;
  display: inline-block;
}

.Wrapper {
  width: 30rem;
  height: 30rem;
  margin: 1rem 3rem;
  color: #003166;
}

.CheckboxWrapper {
  width: 18rem;
  margin: 1rem auto auto;
  display: flex;
  justify-content: space-between;
}

.Checkbox {
  width: 15rem;
}

.Label {
  margin-left: 1.5rem;
  font-size: 0.8rem;
}

.Wrapper h1 {
  color: black;
  text-align: center;
  font-weight: 500;
}

.Body {
  text-align: center;
}

.Body p {
  margin-top: 0;
}

.SamplerSelector {
  max-width: 200px;
  width: 100%;
  margin-top: 2rem;
}

.SubmitWrapper {
  margin-top: 1.5rem;
  text-align: center;
}

.FieldInStateWrapper {
  align-items: center;
  margin: auto;
}

.FieldInStateWrapper p {
  margin-bottom: 1rem;
  text-align: center;
}

.Tag {
  align-items: center;
  border: 1px solid #003166;
  border-radius: 0.25rem;
  display: flex;
  height: 1.5rem;
  justify-content: space-between;
  max-width: 16rem;
  padding: 0 1rem;
  margin: 0.5rem auto 1rem;
}

.Tag span {
  white-space: nowrap;
}

.Tag span:last-child {
  cursor: pointer;
}

.Input {
  width: 18rem;
  margin-bottom: 0.2rem;
}

.AssignBillingResponsibility {
  width: 20rem;
  margin: 3rem auto auto;
  display: flex;
  text-align: center;
  color: red;
}

.Wrapper {
  margin: 1rem;
  padding: 1rem 10%;
}

.Form {
  display: flex;
  flex-direction: column;
  max-width: 40rem;
}

.Grid {
  box-sizing: border-box;
  display: grid;
  grid-column-gap: 3rem;
  grid-row-gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  margin: 1rem 0;
}

.Inline h1 {
  margin-right: 1rem;
}

.PlateList {
  margin: 0 0 1.25rem 0;
}

.IncompletePlateWarning {
  color: #878484;
  font-size: 0.8rem;
  font-style: italic;
}

.Label {
  color: #113063;
  font-size: 1rem;
}

.Input {
  width: 100%;
  color: black;
}

.Selector {
  width: 100%;
  color: black;
}

.Submission {
  margin-top: 2rem;
}

.Submission div {
  margin-top: 1rem;
}

.Error {
  color: red;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 16rem;
  margin-bottom: 3rem;
}

.LogoImage {
  width: 10rem;
  height: 100%;
  margin-bottom: 1rem;
}

.LogoImage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

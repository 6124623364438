.NewButton {
  font-size: 1rem;
}

.ExpandingColumn {
  flex: 1;
}

.Header > * {
  margin-right: 0.5rem;
}

.PaginationRow {
  background-color: white;
  bottom: 0;
  position: sticky;
  text-align: center;
}

.SpinnerWrapper {
  height: 40vh;
}

.Table {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 13rem);
  overflow-y: scroll;
  padding: 0rem 1rem;
}

.TableHeader {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 10;
}

.Filter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  column-gap: 1rem;
  align-items: center;
  color: #113063;
  margin: 1rem 0;
}

.FilterLabel {
  font-size: 1.1rem;
}

.FieldName {
  word-wrap: break-word;
}
.FieldName:hover {
  cursor: pointer;
  opacity: 0.5;
}

.FailedP {
  color: red;
}

.CropSelect {
  width: 100%;
  height: 3rem;
}

.CropSelect:hover {
  cursor: pointer;
}

.CropInput {
  width: 100%;
  height: 3rem;
  font-size: 0.6rem;
}

.CropImgBox:hover {
  opacity: 0.5;
}

.ShowWhilePrinting {
  display: none;
}

@media print {
  .WrapRow {
    break-inside: avoid !important;
  }
  .HideWhilePrinting {
    display: none;
  }
  .ShowWhilePrinting {
    display: block;
    justify-content: center;
    margin-top: 0.8rem;
  }
}

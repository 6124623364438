.AddUser {
  margin-left: 3rem;
}

.MapActions {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.MapSubmit {
  margin-right: 1rem;
}

.MapWarning {
  color: #d72e3d;
}

.MapWrapper {
  width: 55rem;
  height: 100%;
}

.Map {
  flex: 1;
  height: 35rem;
  min-height: 200px;
  margin-left: 1rem;
  margin-top: 1rem;
  position: relative;
  width: 54rem;
}

.Recenter {
  position: absolute;
  bottom: 2rem;
  right: 4rem;
}

.Remove {
  text-align: center;
}

.UsersInput {
  display: flex;
}

.UsersList {
  display: flex;
  flex-direction: column;
  width: 36rem;
  padding-left: 1rem;
}

.TerritoryNameInput {
  width: 20rem;
}

.SearchBar {
  width: 25rem;
}

.Wrapper {
  display: flex;
  justify-content: space-between;
}

.TerritoryUsersTable {
  text-align: left;
  font-size: 0.9rem;
}

.Modal {
  border-radius: 0.2rem;
  background-color: white;
  left: 50%;
  padding: 1rem;
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50vh;
  z-index: 201;
  width: 90rem;
  text-align: center;
  overflow-y: auto;
  color: #0f3162;
}

.Overlay {
  background-color: gray;
  height: 100%;
  left: 0;
  opacity: 0.3;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;
}

.ValueBox {
  border-radius: 3px;
}

.SquareWrapper {
  height: 3rem;
  position: relative;
}

.NDOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #c3cbd8;
  font-size: small;
  z-index: 1;
}

.NoDataBox {
  background-color: #aaaeb2;
  cursor: auto;
  outline: 1px solid lightgray;
}

.AwaitingResultsBox {
  border-style: dashed;
  border-width: 2px;
}

.EmptyBox {
  cursor: auto;
  outline: 1px solid lightgray;
  background-color: #c4c4c4;
}

.NotAnalyzedBox {
  background-color: #aaaeb2;
  cursor: auto;
  outline: 1px solid lightgray;
}

.HasDataBox {
  background-color: #ffffff;
  cursor: pointer;
  outline: 1px solid lightgray;
}

.SingleAnalytic {
  margin: auto;
  height: 100%;
  width: 100%;
  text-align: center;
}

.SingleAnalytic:hover {
  cursor: pointer;
  opacity: 0.6;
}

.PaperContainer {
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center';
  height: 3rem;
  transform: rotate(180deg);
}

.Box {
  width: 100%;
  display: 'flex';
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.Line {
  position: absolute;
  z-index: 1000;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #ececec;
}

.Line:nth-child(1) {
  top: 33.33%;
}

.Line:nth-child(2) {
  top: 66.67%;
}

.SummaryRow {
  margin-bottom: 1rem;
  width: 100%;
}

.RowName {
  width: 8rem;
  display: inline-block;
}

.Clickable {
  cursor: pointer;
}

.Disclaimer {
  font-size: 0.8rem;
}

.BackButton {
  font-size: 1.3rem;
  font-weight: 600;
  color: #0060ff;
  cursor: pointer;
}

.Title {
  font-size: 1.3rem;
  margin: 1rem 0;
}

.Header {
  border-bottom: 1px solid #ececed;
}

.Wrapper {
  overflow-y: auto;
  flex: 1;
  position: relative;
  display: flex;
}

.FieldsTableHeader {
  color: #878484;
  margin-top: 1rem;
}

.Highlight {
  max-width: 890px;
  width: 100%;
  margin: 2.5rem auto 4rem;
  text-align: center;
}

.Blue {
  color: #0068ff;
}

.Red {
  color: red;
}

.Orange {
  color: #ff8c00;
}

.SmallSpinner {
  height: 1rem;
  margin: 0 auto;
  width: 1rem;
}

.FormControlContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  text-align: left;
}

.NoAgentsExist {
  display: flex;
  justify-content: center;
  margin: 1rem auto;
  width: 100%;
}

.SearchBar {
  height: 2.3rem;
}

.SettingsRow {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
}

.FlexEnd {
  justify-content: flex-end;
}

.Selector {
  width: 14rem;
}

.SelectorMenu {
  display: flex;
  flex-direction: column-reverse;
  bottom: 2.2rem;
}

.Input {
  width: 8rem;
}

.Spinner {
  height: 1.15rem;
  width: 1.15rem;
}

.FullHeightBody {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.PageContent {
  margin: 0 auto;
  width: 95%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem 7%;
}

@media print {
  .PageContent {
    width: 100%;
    padding: 0;
    margin: 0;
  }
}

.FilterTabs {
  margin-right: 1rem;
  justify-content: flex-end;
}

.TabController {
  position: sticky;
  background-color: #ffffff;
  top: 0;
  z-index: 3;
  height: 3rem;
}

@media print {
  .HideWhilePrinting {
    display: none;
  }
}

.Container {
  color: #0f3162;
  display: flex;
  flex-direction: column;
}

.Container p {
  font-size: 1.3rem;
}

.Container span {
  text-align: center;
}

.CenterTabs {
  text-align: center;
  margin: auto auto 2rem;
  padding-bottom: 1rem;
  width: 70rem;
  border-bottom: 1px solid #ececed;
}

.Label {
  font-size: 20px;
  line-height: 1.2;
  color: #031166;
  margin-bottom: 1rem;
}
